export default function reducer(state, action) {
  switch (action.type) {
    case "SET_LOGIN":
      return {
        ...state,
        isLoggedIn: true,
      }
    case "OPEN_MOBILE_NAV":
      return {
        ...state,
        isMobileNavOpen: true,
      }
    case "CLOSE_MOBILE_NAV":
      return {
        ...state,
        isMobileNavOpen: false,
      }
    case "TOGGLE_MOBILE_NAV":
      return {
        ...state,
        isMobileNavOpen: !state.isMobileNavOpen,
      }
    case "SET_TAX":
      return {
        ...state,
        stc: action.payload,
      }
    case "SET_SHIPPING_PRICE":
      return {
        ...state,
        sp: action.payload,
      }
    case "SET_SHIPPING_LOCATION":
      return {
        ...state,
        loc: action.payload,
      }
    case "SET_SHIP_ZIP_SET":
      return {
        ...state,
        szs: action.payload,
      }
    case "SET_SHIP_ZONE_INDEX":
      return {
        ...state,
        szi: action.payload,
      }
    case "SET_SHIPPING_ZONE":
      return {
        ...state,
        sz: action.payload,
      }
    case "SET_POSTAL_CODE":
      return {
        ...state,
        pc: action.payload,
      }
    case "SET_LOGOUT":
      return {
        ...state,
        isLoggedIn: false,
        initial: "",
      }
    case "SET_VERIFIED":
      return {
        ...state,
        isVerified: action.payload,
      }
    case "SET_INITIAL":
      return {
        ...state,
        initial: action.payload,
      }

    case "TOGGLE_LOGIN_DIALOG": {
      return {
        ...state,
        signInDialogOpen: !state.signInDialogOpen,
      }
    }
    case "TOGGLE_SIGNUP_DIALOG": {
      return {
        ...state,
        signUpDialogOpen: !state.signUpDialogOpen,
      }
    }

    // case "TOGGLE_CONFIRM_EMAIL_DIALOG": {
    //   return {
    //     ...state,
    //     confirmEmailDialogOpen: !state.confirmEmailDialogOpen,
    //   }
    // }

    case "SET_ORDER_ID":
      return {
        ...state,
        orderId: action.payload,
      }
    // oip = Number of orders in progress
    case "SET_OIP":
      return {
        ...state,
        oip: action.payload,
      }
    // msg= number of conversations with unread messages
    case "SET_MSG":
      return {
        ...state,
        msg: action.payload,
      }
    case "SET_ALERT_COUNT":
      return {
        ...state,
        alertCount: action.payload,
      }
    case "SET_ALERTS":
      return {
        ...state,
        alerts: action.payload,
      }
    default:
      return state
  }
}
