// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activate-js": () => import("./../../../src/pages/activate.js" /* webpackChunkName: "component---src-pages-activate-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-convert-js": () => import("./../../../src/pages/convert.js" /* webpackChunkName: "component---src-pages-convert-js" */),
  "component---src-pages-create-js": () => import("./../../../src/pages/create.js" /* webpackChunkName: "component---src-pages-create-js" */),
  "component---src-pages-guest-js": () => import("./../../../src/pages/guest.js" /* webpackChunkName: "component---src-pages-guest-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-js": () => import("./../../../src/pages/my.js" /* webpackChunkName: "component---src-pages-my-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-testpage-js": () => import("./../../../src/pages/testpage.js" /* webpackChunkName: "component---src-pages-testpage-js" */),
  "component---src-templates-category-001-js": () => import("./../../../src/templates/category001.js" /* webpackChunkName: "component---src-templates-category-001-js" */),
  "component---src-templates-company-js": () => import("./../../../src/templates/company.js" /* webpackChunkName: "component---src-templates-company-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-gallery-js": () => import("./../../../src/templates/gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */),
  "component---src-templates-item-001-js": () => import("./../../../src/templates/Item001.js" /* webpackChunkName: "component---src-templates-item-001-js" */),
  "component---src-templates-item-002-js": () => import("./../../../src/templates/Item002.js" /* webpackChunkName: "component---src-templates-item-002-js" */),
  "component---src-templates-item-003-js": () => import("./../../../src/templates/Item003.js" /* webpackChunkName: "component---src-templates-item-003-js" */),
  "component---src-templates-product-002-js": () => import("./../../../src/templates/product002.js" /* webpackChunkName: "component---src-templates-product-002-js" */),
  "component---src-templates-products-js": () => import("./../../../src/templates/products.js" /* webpackChunkName: "component---src-templates-products-js" */)
}

