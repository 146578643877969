import React, { useState } from "react"
import { Box, Grid, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import UserOrderItemDetails from "./UserOrderItemDetails"
import UserPrivateItemDetails from "./UserPrivateItemDetails"
import { useSiteMetadata } from "../../utils/hooks/useSiteMetadata"
const ItemNotFound = ({ id, lookupType }) => {
  return (
    <div>
      <Typography>
        We couldn't find that item {id}: {lookupType}
      </Typography>
    </div>
  )
}
const UserItemDetails = ({ classes, id, lookupType }) => {
  const { sbuId } = useSiteMetadata()
  const itemSwitch = lookupType => {
    switch (lookupType) {
      case "orderItem":
        return <UserOrderItemDetails id={id} sbuId={sbuId} />
      case "privateItem":
        return <UserPrivateItemDetails id={id} sbuId={sbuId} />
      default:
        return <ItemNotFound id={id} lookupType={lookupType} />
    }
  }
  return <>{itemSwitch(lookupType)}</>
}

const styles = theme => ({
  root: {},
})
export default withStyles(styles)(UserItemDetails)
