import React, { createContext, useEffect } from "react"

const UserContext = createContext({
  isMobileNavOpen: false,
  isLoggedIn: false,
  isVerified: false,
  initial: "",
  orderId: null,
  oip: 0,
  msg: 0,
  loc: "",
  sz: "",
  szi: null,
  sp: 0.0,
  stc: 1,
  pc: "",
  szs: false,
  alertCount: 0,
  alerts: [],
})

export default UserContext
