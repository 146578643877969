import React, { useState, useEffect } from "react"
import gql from "graphql-tag"
import { useQuery, useMutation } from "@apollo/client"
import { useSiteMetadata } from "../../utils/hooks/useSiteMetadata"

// import Loading from "../../Shared/Loading"
// import Error from "../../Shared/Error"

const SaveCart = ({ uid }) => {
  const cart = JSON.parse(localStorage.getItem("ie-cart"))
  const { sbuId } = useSiteMetadata()
  const id = cart.id
  const items = cart.items
  const started = cart.started
  const latestChange = cart.latestUpdate
  const totalItems = cart.totalItems
  const uniqueItems = cart.totalUniqueItems
  const isEmpty = cart.isEmpty

  const [data, loading, error] = useQuery(ME_CART_QUERY, {
    variables: {
      cartId: id,
    },
    onCompleted: data => {
      let openCart = data.cart.cartOpen
      let expiredCart = data.cart.cartExpired
      let lastSaved = data.cart.latestChange
      if (
        openCart === true &&
        expiredCart === false &&
        lastSaved >= latestChange
      ) {
      } else console.log("put server data in local storage")
    },
  })
  useEffect(() => {
    {
      uid.length < 5 && handleSaveCart()
    }
  }, [uid])
  const [handleSaveCart] = useMutation(SAVE_CART_MUTATION, {
    variables: {
      cartId: id,
      businessUnitId: sbuId,
      personUid: uid,
      items: items,
      totalItems: totalItems,
      totalUniqueItems: uniqueItems,
      started: started,
      latestChange: latestChange,
      cartOpen: true,
    },
    onCompleted: data => {
      console.log(data)
    },
  })

  if (loading) return "loading"
  if (error) return "error"
  return null
}

const ME_CART_QUERY = gql`
  query($cartId: String) {
    cart(cartId: $cartId) {
      cartId
      cartExpired
      cartOpen
      latestChange
      items
    }
  }
`

const SAVE_CART_MUTATION = gql`
  mutation(
    $cartId: String
    $businessUnitId: Int
    $personUid: String
    $items: String
    $totalItems: Int
    $totalUniqueItems: Int
    $started: DateTime
    $latestChange: DateTime
    $cartOpen: Boolean
  ) {
    updateCart(
      cartId: $cartId
      businessUnitId: $businessUnitId
      personUid: $personUid
      items: $items
      totalItems: $totalItems
      totalUniqueItems: $totalUniqueItems
      started: $started
      latestChange: $latestChange
      cartOpen: $cartOpen
    ) {
      cart {
        id
        cartId
        person {
          uid
          firstName
          lastName
        }
        items
        totalItems
        totalUniqueItems
        businessUnit {
          id
          name
        }
        started
        latestChange
        cartOpen
        cartExpired
      }
    }
  }
`
export default SaveCart
