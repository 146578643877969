import React, { useState, useContext, useEffect } from "react"
import PropTypes from "prop-types"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"
import { useLocation } from "@reach/router"
import headerLogoSVG from "../../../images/ICON-Emblem-logo.svg"
import { graphql, useStaticQuery, Link, navigate } from "gatsby"

import UserContext from "../../../context/UserContext"
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Container,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Slide,
  Tabs,
  Tab,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core"

import { withStyles } from "@material-ui/styles"

import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import AccountCircle from "@material-ui/icons/AccountCircle"
import PersonIcon from "@material-ui/icons/Person"
import HeaderCartButton from "./HeaderCartButton"

export const menuItems = [
  {
    id: 1,
    title: "Shop",
    href: "/shop",
    icon: "",
    displayBreakpoint: "hideXs",
  },
  {
    id: 2,
    title: "Custom Items",
    href: "/products",
    icon: "",
    displayBreakpoint: "hideXs",
  },
  {
    id: 3,
    title: "Galleries",
    href: "/gallery",
    icon: "",
    displayBreakpoint: "hideXs",
  },
  {
    id: 4,
    title: "Company",
    href: "/company",
    icon: "",
    displayBreakpoint: "hideSmDown",
  },
  {
    id: 5,
    title: "Contact",
    href: "/contact",
    icon: "",
    displayBreakpoint: "hideSmDown",
  },
]

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 50,
      width: "100%",
      backgroundColor: "#b71c1c",
    },
  },
})(props => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />)

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: "uppercase",
    color: "#000",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: "2px",
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
    },
  },
}))(props => <Tab disableRipple {...props} />)

const Header = ({ classes, props }) => {
  const { state, dispatch } = useContext(UserContext)
  const isLoggedIn = state.isLoggedIn
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [value, setValue] = useState(false)
  const [expanded, setExpanded] = useState(true)
  const location = useLocation()

  const handleNavTab = item => {
    let href = item.href
    let id = item.id
    let tabValue = id - 1
    navigate(href)
    setValue(tabValue)
  }

  useEffect(() => {
    let pathname = location.pathname
    console.log(pathname)
    let paths = ["/shop", "/products", "/gallery", "/company", "/contact"]
    if (paths.includes(pathname)) {
      let thisPath = menuItems.filter(element => element.href === pathname)[0]
      let val = thisPath.id - 1
      setValue(val)
    }
  }, [])

  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const [handleSignOut, { loading, error }] = useMutation(LOGOUT_MUTATION, {
    onCompleted() {
      navigate("/")
    },
  })

  const handleLogout = () => {
    dispatch({
      type: "SET_LOGOUT",
    })
    dispatch({ type: "SET_VERIFIED", payload: false })
    dispatch({ type: "SET_INITIAL", payload: "" })
    handleSignOut()
  }

  const handleNavigate = () => {
    navigate("/my")
    setAnchorEl(null)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const data = useStaticQuery(getImages)
  const headerLogo = data.logo.childImageSharp.fluid
  return (
    <div className={classes.root}>
      {!location.pathname.includes("/my") && (
        <Hidden xsDown>
          <Toolbar
            component={Paper}
            elevation={2}
            square
            disableGutters
            variant="dense"
            className={classes.blackBar}
          >
            <Box component={Container} className={classes.blackBarInnerBox}>
              <Typography
                variant="body2"
                className={classes.blackBoxText}
                display="inline"
              >
                Patches, Pins and More!
              </Typography>
              <PhoneAndroidIcon style={{ fontSize: 17, paddingRight: 4 }} />
              <Typography
                variant="body2"
                className={classes.blackBoxText}
                display="inline"
              >
                <a className={classes.link} href="tel:312-339-0013">
                  312-339-0013
                </a>
              </Typography>
              <MailOutlineIcon style={{ fontSize: 17, paddingRight: 4 }} />
              <Typography
                variant="body2"
                className={classes.blackBoxText}
                display="inline"
              >
                <a className={classes.link} href="mailto:order@iconemblem.com">
                  order@iconemblem.com
                </a>
              </Typography>
              <div className={classes.grow} />

              <FacebookIcon />
              <InstagramIcon />
            </Box>
          </Toolbar>
        </Hidden>
      )}

      <Toolbar
        component={Paper}
        elevation={2}
        square
        className={classes.whiteBar}
      >
        <Box component={Container} className={classes.innerWhiteBar}>
          <Link to={"/"}>
            <img
              src={headerLogoSVG}
              className={classes.logo}
              alt="ICON Emblem"
            />
          </Link>
          <div className={classes.grow} />
          <div className={classes.tabs}>
            <StyledTabs value={value} aria-label="ICON Emblem Menu">
              {menuItems.map((item, index) => {
                let breakPoint = item.displayBreakpoint
                let href = item.href

                return (
                  <StyledTab
                    key={item.id}
                    className={
                      breakPoint === "hideSmDown"
                        ? classes.hideSmDown
                        : classes.hideXs
                    }
                    label={item.title}
                    onClick={() => handleNavTab(item)}
                  />
                )
              })}
            </StyledTabs>
          </div>

          <Box display="inline">
            <HeaderCartButton />
          </Box>
          {isLoggedIn ? (
            <>
              <Tooltip title="Profile">
                <IconButton
                  edge="end"
                  aria-label={state.initial}
                  color="secondary"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  style={{ width: "45px", height: "45px" }}
                  onClick={handleMenu}
                >
                  {/* <Avatar className={classes.avatar}>{state.initial}</Avatar> */}

                  <Badge badgeContent={state.alertCount} color="secondary">
                    <Avatar className={classes.avatar}>{state.initial}</Avatar>
                  </Badge>
                </IconButton>
              </Tooltip>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
                elevation={1}
                className={classes.menu}
              >
                <MenuItem className={classes.menuItem} onClick={handleNavigate}>
                  Main Menu
                </MenuItem>
                <MenuItem className={classes.menuItem} onClick={handleLogout}>
                  Sign Out
                </MenuItem>
              </Menu>
            </>
          ) : (
            <Tooltip title="Profile">
              <IconButton
                // edge="end"
                aria-label={state.initial}
                color="inherit"
                onClick={handleNavigate}
                // size="small"
                style={{ width: "45px", height: "45px" }}
              >
                <AccountCircle style={{ fontSize: "22px" }} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Toolbar>
    </div>
  )
}

const styles = theme => ({
  root: {
    // paddingTop: "10px",
    position: "fixed",
    width: "100%",
    zIndex: 99,
  },
  avatar: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  blackBar: {
    backgroundColor: theme.palette.common.black,
    color: "#D3D3D3",
    width: "100%",
    padding: theme.spacing(0),
    minHeight: theme.spacing(5),
  },
  blackBarInnerBox: {
    display: "flex",
    flexDirection: "row",
  },
  whiteBarInnerBox: {
    display: "flex",
    flexDirection: "row",
    paddingTop: theme.spacing(1),
  },
  blackBoxText: {
    color: "#d3d3d3",
    marginRight: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  hideXs: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  hideSmDown: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  noGrow: {
    flexGrow: 0,
  },
  logo: {
    marginBottom: 0,
    height: theme.spacing(6),

    // [theme.breakpoints.only("xs")]: {
    //   height: theme.spacing(4),
    // },
    // [theme.breakpoints.only("xxs")]: {
    //   height: theme.spacing(3),
    // },
  },
  whiteBar: {
    // height: theme.spacing(8),
    // hideXs: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(1),
    },

    // padding: "5, auto",
    display: "flex",
    // backgroundColor: theme.palette.background.paper,
    // color: theme.palette.common.black,
  },
  innerWhiteBar: {
    // height: "50px",
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.black,
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(1),
    },
    top: 0,
    // height: theme.spacing(8),
    // hideXs: {
    //   [theme.breakpoints.down("xs")]: {
    //     paddingTop: theme.spacing(3),
    //   },
    // },
    // padding: "0, auto",
    display: "flex",
  },
  // toolbarBox: {
  //   display: "flex",

  //   // marginLeft: "auto",
  //   // marginRight: "auto",
  // },
  link: {
    textDecoration: "none",
    color: theme.palette.common.blue,
  },
  menuItem: {
    "&:hover": {
      textDecoration: "none",
      backgroundColor: theme.palette.action.hoverLight,
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
})

const LOGOUT_MUTATION = gql`
  mutation {
    deleteTokenCookie {
      deleted
    }
  }
`
const getImages = graphql`
  {
    logo: file(relativePath: { eq: "icon-emblem-logo-1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default withStyles(styles)(Header)
