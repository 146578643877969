import React, { useState, useContext } from "react"
import SignIn from "./SignIn"
import SignUp from "./SignUp"
import UserContext from "../../context/UserContext"
import VerifyAccount from "./VerifyAccount"
import PasswordReset from "./PasswordReset"
import Layout from "../../components/Layout/layout"
const Sign = () => {
  const { state, dispatch } = useContext(UserContext)
  const [newUser, setNewUser] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)
  const [needsVerification, setNeedsVerification] = useState(true)
  const isLoggedIn = state.isLoggedIn

  return (
    <Layout>
      {forgotPassword ? (
        <PasswordReset
          setNewUser={setNewUser}
          setForgotPassword={setForgotPassword}
        />
      ) : (
        <>
          {newUser ? (
            <SignUp setNewUser={setNewUser} />
          ) : (
            <>
              {isLoggedIn ? (
                <VerifyAccount />
              ) : (
                <SignIn
                  setNewUser={setNewUser}
                  setForgotPassword={setForgotPassword}
                />
              )}
            </>
          )}
        </>
      )}
    </Layout>
  )
}

export default Sign
