import React, { useState } from "react"
import { Link } from "gatsby"
import clsx from "clsx"
import withStyles from "@material-ui/core/styles/withStyles"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"
import CardActions from "@material-ui/core/CardActions"
import IconButton from "@material-ui/core/IconButton"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Collapse from "@material-ui/core/Collapse"
import Grid from "@material-ui/core/Grid"

import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
import InboxIcon from "@material-ui/icons/Inbox"
import DraftsIcon from "@material-ui/icons/Drafts"
import {
  AlertCircle as AlertCircleIcon,
  Check as CheckIcon,
  ThumbsUp as ThumbsUpIcon,
  ThumbsDown as ThumbsDownIcon,
} from "react-feather"

// Values here are the same as the DB keys. Working array is pulled from a query. Use statusOptions as a reference only.
const statusOptions = [
  { id: 1, name: "Art/Proof in progress", value: 1 },
  { id: 2, name: "Art/Proof awaiting customer approval", value: 2 },
  { id: 3, name: "Sample awaiting customer approval", value: 3 },
  { id: 4, name: "Art/Proof approved by customer", value: 4 },
  { id: 5, name: "Revision in progress", value: 5 },
  { id: 6, name: "Sample creation in progress", value: 6 },
  { id: 7, name: "Sample approved by customer", value: 7 },
  { id: 8, name: "Discrepancy", value: 8 },
  { id: 9, name: "Rejected and abandoned", value: 9 },
]

const UserItemPreproduction = ({
  classes,
  item,
  expandedPrePro,
  setExpandedPrePro,
}) => {
  const sampleProofs = item.sampleProofItem ? item.sampleProofItem : []

  const handleExpandClick = () => {
    setExpandedPrePro(!expandedPrePro)
  }

  const iconSwitch = statusId => {
    switch (statusId) {
      case "4" || "7":
        return <ThumbsUpIcon />

      case "2" || "3":
        // case 2 || 3:
        return <AlertCircleIcon />

      case "8" || "9":
        return <ThumbsDownIcon />

      default:
        return ""
    }
  }

  return (
    <div className={classes.root}>
      <Card square className={classes.paper} elevation={0} variant="outlined">
        <CardActions disableSpacing>
          <Typography variant="h5" className={classes.title}>
            Pre-Production
          </Typography>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expandedPrePro,
            })}
            onClick={handleExpandClick}
            aria-expanded={expandedPrePro}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expandedPrePro} timeout="auto" unmountOnExit>
          <Box pl={1} pr={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                {sampleProofs.length > 0 ? (
                  <>
                    <Typography variant="h6">Samples and Proofs</Typography>

                    <List aria-label="samples and proofs">
                      {sampleProofs &&
                        sampleProofs.map(sampleProof => {
                          const proofId = sampleProof.id
                          const statusId = sampleProof.status.id
                          const statusName = sampleProof.status.name
                          return (
                            <ListItem
                              key={proofId}
                              button
                              component={Link}
                              to={`./proof/${proofId}`}
                              state={{ proofId: proofId }}
                              className={classes.sampleList}
                            >
                              <ListItemIcon>
                                {iconSwitch(statusId)}
                              </ListItemIcon>
                              <ListItemText
                                primary={`Sample Proof # ${proofId}`}
                                secondary={statusName}
                              />
                            </ListItem>
                          )
                        })}
                    </List>
                  </>
                ) : (
                  "No samples or proofs on file"
                )}
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
            </Grid>
          </Box>
        </Collapse>
      </Card>
    </div>
  )
}

const styles = theme => ({
  root: {
    // margin: theme.spacing(0, 0.5, 0.5, 0.5),
    minWidth: 225,
  },
  title: {},
  paper: {
    padding: theme.spacing(1),
    boxShadow: "none",
    borderRadius: "15px",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  sampleList: {
    "&:hover": {
      textDecoration: "none",
      backgroundColor: theme.palette.action.hoverLight,

      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
})

export default withStyles(styles)(UserItemPreproduction)
