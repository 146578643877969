import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"

const Copyright = () => {
  const data = useStaticQuery(graphql`
    query CopyrightQuery {
      site {
        siteMetadata {
          title
          url
        }
      }
    }
  `)
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href={data.site.siteMetadata.url}>
        {data.site.siteMetadata.title}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}
// export const query = graphql`
//   query CopyrightQuery {
//     site {
//       siteMetadata {
//         title
//         url
//       }
//     }
//   }
// `
export default Copyright
