import React, { useState, useContext } from "react"

import gql from "graphql-tag"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core"

import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Link from "gatsby"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import { makeStyles } from "@material-ui/core/styles"
import FindPerson from "./FindPerson"
import UserContext from "../../context/UserContext"
import { navigate } from "gatsby"
import useDidMountEffect from "../utils/hooks/useDidMountEffect"
import VerifyAccount from "./VerifyAccount"
import Sign from "./index"
import Copyright from "../utils/Copyright"
import { useSiteMetadata } from "../utils/hooks/useSiteMetadata"
// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {"Copyright © "}
//       <Link color="inherit" href="https://material-ui.com/">
//         Your Website
//       </Link>{" "}
//       {new Date().getFullYear()}
//     </Typography>
//   )
// }

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(7),
    display: "flex",
    flexDirection: "column",
  },
  paper: {
    backgroundColor: "#ffffff",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formSection: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  contrastButton: {
    width: "100%",
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    textTransform: "uppercase",
    fontWeight: "bold",
    padding: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.common.almostBlack,
    },
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(2),
  },
}))

const PasswordReset = ({ setNewUser, setForgotPassword }) => {
  const { state, dispatch } = useContext(UserContext)

  const [email, setEmail] = useState("")
  const [linkSent, setLinkSent] = useState(false)
  const [password, setPassword] = useState("")
  const classes = useStyles()
  const { sbuId } = useSiteMetadata()
  useDidMountEffect(() => {
    navigate("/my")
  }, [state.isLoggedIn])

  //   const [handleSubmit, { loading, error }] = useMutation(SIGNUP_MUTATION, {
  //     variables: {
  //       firstName: firstName,
  //       lastName: lastName,
  //       email: email,
  //       password: password,
  //       sbuId: 1,
  //     },
  //     onCompleted: data => {
  //       dispatch({ type: "SET_LOGIN" })
  //       handleLogin()
  //     },
  //   })

  const [handleSubmit, { loading, data, error }] = useLazyQuery(
    PASSWORD_RESET_INIT_QUERY,
    {
      variables: {
        email: email,
        sbuId: sbuId,
      },
    }
  )

  const handleSuccess = event => {
    setLinkSent(true)
  }

  if (loading) return "loading"
  if (error) return "error"
  if (linkSent === true)
    return (
      <div className={classes.root}>
        <Container maxWidth="sm">
          <Paper square variant="outlined" className={classes.paper}>
            <div className={classes.formSection}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Password Reset Initiated
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                align="center"
                gutterBottom
              >
                Please check your email for a link to reset your password. If
                you don't receive an email, it likely means that we don't have
                this email address on file.
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                align="center"
                gutterBottom
              >
                Any problems? Please contact customer support.
              </Typography>
            </div>
          </Paper>
          <Box mt={5}>
            <Copyright />
          </Box>
        </Container>
      </div>
    )

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Paper square variant="outlined" className={classes.paper}>
          <div className={classes.formSection}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              New Password Request
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
              Please enter the email address you use to sign in to this account.
              We'll first verify your account and then send you a link so you
              can create a new password.
            </Typography>

            <form
              className={classes.form}
              noValidate
              onSubmit={event => {
                event.preventDefault()
                handleSubmit(event)
                handleSuccess()
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                  />
                </Grid>
              </Grid>
              {/* <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                //   disabled={loading || !email.trim() || !password.trim()}
              >
                Request new password
              </Button> */}
              <ButtonBase
                className={classes.contrastButton}
                type="submit"
                disabled={loading || !email.trim()}
              >
                <Typography
                  component="h1"
                  className={classes.boxTitle}
                  color="inherit"
                  gutterBottom
                >
                  {loading ? "Just a sec" : "Request a new password"}
                </Typography>
              </ButtonBase>
              <Grid container>
                <Grid item>
                  <ButtonBase
                    disableRipple
                    style={{ width: "100%" }}
                    onClick={e => setForgotPassword(false)}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: "18px",
                        paddingLeft: "auto",
                        paddingRight: "auto",
                        marginTop: "15px",
                      }}
                    >
                      Know your password? Sign in here.
                    </Typography>
                  </ButtonBase>
                  {/* <Link
                    component="button"
                    bariant="body2"
                    onClick={e => setForgotPassword(false)}
                  >
                    {"Know your password? Sign in here."}
                  </Link> */}
                </Grid>
              </Grid>
            </form>
          </div>
        </Paper>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    </div>
  )
}

const PASSWORD_RESET_INIT_QUERY = gql`
  query($email: String!, $sbuId: Int!) {
    passwordResetInit(email: $email, sbuId: $sbuId) {
      email
    }
  }
`
export default PasswordReset
