import React, { useState, useContext, useEffect, useRef } from "react"
// import { Stage, Layer, Shape, Text } from "react-konva"
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { ShoppingCart as CartIcon } from "react-feather"
import withStyles from "@material-ui/core/styles/withStyles"
import AddToCartButton from "../../../components/DropIn/AddToCartButton"
import { useCart } from "../../../components/User/UserCart/Cart"
import UserContext from "../../../context/UserContext"
import { Link } from "gatsby"

const CTABadgeDesigner = ({ productInfo, classes }) => {
  const { state } = useContext(UserContext)
  const isLoggedIn = state.isLoggedIn
  const [success, setSuccess] = useState(false)
  const { addItem } = useCart()

  const canvasRef = useRef()
  const canvasWidth = 315
  const canvasHeight = 175

  const badgeShape = (x, y, w, h, radius) => {
    var canv = canvasRef.current
    if (canv.getContext) {
      var ctx = canv.getContext("2d")
      ctx.clearRect(0, 0, 315, 200)
      var r = x + w
      var b = y + h
      ctx.beginPath()

      ctx.strokeStyle = "#800020"
      ctx.lineWidth = 10

      ctx.moveTo(x + radius, y)
      ctx.lineTo(r - radius, y)
      ctx.quadraticCurveTo(r, y, r, y + radius)
      ctx.lineTo(r, y + h - radius)
      ctx.quadraticCurveTo(r, b, r - radius, b)
      ctx.lineTo(x + radius, b)
      ctx.quadraticCurveTo(x, b, x, b - radius)
      ctx.lineTo(x, y + radius)
      ctx.quadraticCurveTo(x, y, x + radius, y)
      ctx.stroke()
    }
  }
  const writeBadgeNumber = (number, style) => {
    var canv = canvasRef.current
    if (canv.getContext) {
      var ctx = canv.getContext("2d")
      ctx.fillStyle = "#800020"
      if (style === "1") {
        ctx.font = "90px Arial"
        ctx.clearRect(22, 33, 270, 80)
        ctx.fillText(number, 31, 108)
      }
      if (style === "2") {
        ctx.font = "95px Arial"
        ctx.clearRect(25, 36, 265, 97)
        ctx.fillText(number, 23, 118)
      }
    }
  }
  const [qty, setQty] = useState(1)
  const options = productInfo.options
  const weightEach = productInfo.weightEach
  const weightUnits = productInfo.weightUnits
  const urlSlug = productInfo.urlSlug
  const quantityOptions = [1, 2, 3, 4, 5]
  const [badgeNumber, setBadgeNumber] = useState(12345)
  const [badgeStyle, setBadgeStyle] = useState("1")
  const [orderQty, setOrderQty] = useState(25)
  const [badgeError, setBadgeError] = useState(false)
  const [itemName, setItemName] = useState(
    `CTA Badge Patches: New B-Style (3.75 inches * 1.75 inches) Badge number ${badgeNumber}`
  )
  const aStyleName =
    "CTA Badge Patches: Classic A-Style (3.875 inches * 1.25 inches). Badge number"
  const bStyleName =
    "CTA Badge Patches: New B-Style (3.75 inches * 1.75 inches) Badge number"

  useEffect(() => {
    let updatedQty = 25 * qty
    setOrderQty(updatedQty)
    setItemState({ ...itemState, quantity: updatedQty })
  }, [qty])

  useEffect(() => {
    let styleName = badgeStyle === "1" ? aStyleName : bStyleName
    let newItemName = styleName + " " + badgeNumber
    setItemName(newItemName)
    setItemState({
      ...itemState,
      customFields: { badgeNumber: parseInt(badgeNumber) },
      name: newItemName,
    })
    writeBadgeNumber(badgeNumber, badgeStyle)
  }, [badgeNumber])

  useEffect(
    options => {
      let styleName = badgeStyle === "1" ? aStyleName : bStyleName
      let newItemName = styleName + " " + badgeNumber
      setItemName(newItemName)
      setItemState({
        ...itemState,
        id: parseInt(badgeStyle),
        name: newItemName,
      })
      if (badgeStyle === "1") {
        badgeShape(13, 25, 290, 100, 20)
        writeBadgeNumber(badgeNumber, badgeStyle)
      }
      if (badgeStyle === "2") {
        badgeShape(18, 25, 280, 120, 20)
        writeBadgeNumber(badgeNumber, badgeStyle)
      }
    },
    [badgeStyle]
  )

  const handleChangeBadge = event => {
    setBadgeNumber(event.target.value)
  }
  const handleStyleChange = event => {
    setBadgeStyle(event.target.value)
  }

  const [itemState, setItemState] = useState({
    id: Number.parseFloat(`${badgeStyle}`),
    name: itemName,
    customFields: { badgeNumber: badgeNumber },
    price: 1.78,
    quantity: orderQty,
    weightEach: weightEach,
    weightUnits: weightUnits,
    urlSlug: urlSlug,
  })

  const addToCart = () => {
    if (badgeNumber === 12345) setBadgeError(true)
    else {
      setSuccess(true)
      addItem(itemState)
    }
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xxs={12} xs={12}>
          {/* <Grid item xxs={12} xs={12} sm={4}> */}
          <Box mt={2}>
            <TextField
              label="Badge Number"
              size="small"
              margin="dense"
              value={badgeNumber}
              onChange={handleChangeBadge}
              variant="outlined"
              inputProps={{ maxLength: 5 }}
            />
          </Box>
          <Box mt={3}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Select an Option</FormLabel>
              <RadioGroup
                aria-label="option"
                name="option1"
                value={badgeStyle}
                onChange={handleStyleChange}
              >
                <FormControlLabel
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                  value="1"
                  control={<Radio />}
                  label={
                    <Typography>Classic "A" Style: 3.875" * 1.25"</Typography>
                  }
                />

                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label={<Typography>New "B" Style: 3.75" * 1.75"</Typography>}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xxs={12} xs={12}>
          <Box className={classes.stageBox}>
            <canvas
              ref={canvasRef}
              width={canvasWidth}
              height={canvasHeight}
              style={{ marginBottom: "10px" }}
            ></canvas>
          </Box>
          {badgeError && (
            <Alert
              style={{ width: "300px" }}
              variant="outlined"
              severity="error"
              onClose={() => setBadgeError(false)}
            >
              <AlertTitle>Please enter a valid badge number</AlertTitle>
            </Alert>
          )}
          <>
            {success ? (
              <Alert
                style={{ maxWidth: "300px" }}
                variant="outlined"
                severity="success"
                onClose={() => setSuccess(false)}
              >
                <AlertTitle>Great choice!</AlertTitle>
                <Button
                  size="small"
                  startIcon={<CartIcon />}
                  component={Link}
                  to={isLoggedIn ? "/my/cart" : "/cart"}
                >
                  Go to your Cart
                </Button>
              </Alert>
            ) : (
              <AddToCartButton
                quantityOptions={quantityOptions}
                qty={qty}
                setQty={setQty}
                onAddToCart={addToCart}
              />
            )}
          </>
        </Grid>
      </Grid>
    </>
  )
}

const styles = theme => ({
  root: {},
  boxTitle: {
    fontSize: "15px",
    letterSpacing: "1px",
    textTransform: "uppercase",
  },
  contrastButton: {
    width: "90%",
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    textTransform: "uppercase",
    padding: theme.spacing(1.5),
    "&:hover": {
      backgroundColor: theme.palette.common.almostBlack,
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    marginLeft: "auto",
    marginRight: "auto",
  },
  stageBox: {
    width: 315,
  },
})
export default withStyles(styles)(CTABadgeDesigner)
