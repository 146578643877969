import { ApolloClient, InMemoryCache } from "@apollo/client"
import fetch from "isomorphic-fetch"
import { createUploadLink } from "apollo-upload-client"
// graphql client configuration
// Apollo upload client

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: createUploadLink({
    uri: "https://segmentnation.com/graphql/",
    // uri: "https://www.segmentnation.app/graphql/",
    // uri: "http://localhost:8000/graphql/",
    fetch,
    credentials: "include",
  }),
})
