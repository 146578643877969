import React, { useContext } from "react"
import gql from "graphql-tag"
import { useQuery, useMutation } from "@apollo/client"
import LOGIN_QUERY from "./SignUp"
import UserContext from "../../context/UserContext"
const CreatePerson = ({ firstName, lastName }) => {
  const { state, dispatch } = useContext(UserContext)
  const [handleCreatePerson] = useMutation(CREATE_PERSON_MUTATION, {
    variables: {
      firstName: { firstName },
      lastName: { lastName },
      middleName: "",
      suffix: "",
      isSupplier: false,
      nickname: "",
      title: "",
      about: "",
    },
    refetchQueries: [{ query: LOGIN_QUERY }],
    onCompleted() {
      // setFirstName("")
      // setPassword("")
      // dispatch({ type: "TOGGLE_LOGIN" })
      dispatch({ type: "SET_LOGIN" })
      dispatch({ type: "TOGGLE_LOGIN_DIALOG" })
    },
  })
  return <div></div>
}
const CREATE_PERSON_MUTATION = gql`
  mutation(
    $firstName: String!
    $lastName: String!
    $middleName: String
    $suffix: String
    $nickname: String
    $title: String
    $about: String
    $isSupplier: Boolean
  ) {
    createPerson(
      firstName: $firstName
      lastName: $lastName
      middleName: $middleName
      suffix: $suffix
      isSupplier: $isSupplier
      nickname: $nickname
      title: $title
      about: $about
    ) {
      person {
        firstName
        lastName
      }
    }
  }
`
export default CreatePerson
