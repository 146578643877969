import React, { createContext, useContext, useReducer, useEffect } from "react"
import useLocalStorage from "./useLocalStorage"
import UserItemDetails from "../UserItems/UserItemDetails"
import { v4 as uuidv4 } from "uuid"

const ADD_ITEM = "ADD_ITEM"
const UPDATE_ITEM = "UPDATE_ITEM"
const REMOVE_ITEM = "REMOVE_ITEM"
const EMPTY_CART = "EMPTY_CART"
const CLOSE_CART = "CLOSE_CART"
const UPDATE_CART_ID = "UPDATE_CART_ID"

const CartContext = createContext()
const now = new Date().toISOString()
const isBrowser = () => typeof window !== "undefined"

const initialState = {
  items: [],
  totalItems: 0,
  totalUniqueItems: 0,
  totalWeight: 0,
  isEmpty: true,
  started: now,
  latestUpdate: now,
  isOpen: true,
}

export const useCart = () => useContext(CartContext)

function reducer(state, action) {
  switch (action.type) {
    case ADD_ITEM: {
      const items = [...state.items, action.payload]

      return generateCartState(state, items)
    }

    case UPDATE_ITEM: {
      const items = state.items.map(item => {
        if (item.id !== action.id) return item

        return {
          ...item,
          ...action.payload,
        }
      })

      return generateCartState(state, items)
    }

    case REMOVE_ITEM: {
      const items = state.items.filter(i => i.id !== action.id)

      return generateCartState(state, items)
    }

    case EMPTY_CART:
      return initialState

    case UPDATE_CART_ID:
      return {
        ...state,
        id: uuidv4(),
      }

    case CLOSE_CART:
      return {
        ...state,
        isOpen: false,
      }
    default:
      throw new Error("No action specified")
  }
}

const generateCartState = (state, items, isOpen) => {
  const totalUniqueItems = calculateUniqueItems(items)
  const isEmpty = totalUniqueItems === 0
  const latestUpdate = now
  return {
    ...state,
    items,
    totalItems: calculateTotalItems(items),
    totalUniqueItems,
    cartTotal: calculateCartTotal(items),
    totalWeight: calculateTotalWeight(items),
    isEmpty,
    latestUpdate,
    isOpen,
  }
}

const calculateCartTotal = items =>
  items.reduce((total, item) => total + item.quantity * item.price, 0)

const calculateTotalItems = items =>
  items.reduce((sum, item) => sum + item.quantity, 0)

const calculateTotalWeight = items =>
  items.reduce((sum, item) => sum + item.quantity * item.weightEach, 0)

const calculateUniqueItems = items => items.length

export function CartProvider({
  children,
  id,
  defaultItems = [],
  onItemAdd,
  onItemUpdate,
  onItemRemove,
}) {
  if (!id) {
    throw new Error(
      "That didn't work. Try again. You must set an `id` when mounting the CartProvider"
    )
  }

  const [savedCart, saveCart] = useLocalStorage(
    "ie-cart",
    JSON.stringify({
      id,
      ...initialState,
      items: defaultItems,
    })
  )

  const [state, dispatch] = useReducer(reducer, JSON.parse(savedCart))

  useEffect(() => {
    saveCart(JSON.stringify(state))
  }, [state, saveCart])

  const addItem = (item, quantity = item.quantity) => {
    if (quantity <= 0) return
    if (!item.id) throw new Error("You must provide an `id` for items")

    const currentItem = state.items.find(i => i.id === item.id)

    if (!currentItem & !item.price)
      throw new Error("You must pass a `price` for new items")

    if (!currentItem)
      return dispatch({ type: ADD_ITEM, payload: { ...item, quantity } })

    const payload = { ...item, quantity: currentItem.quantity }
    // const payload = { ...item, quantity: currentItem.quantity + quantity }

    dispatch({
      type: UPDATE_ITEM,
      id: item.id,
      payload,
    })

    onItemAdd && onItemAdd(payload)
  }

  const updateItem = (id, payload) =>
    dispatch({ type: UPDATE_ITEM, id, payload })

  const updateItemQuantity = (id, quantity) => {
    if (quantity <= 0) return dispatch({ type: REMOVE_ITEM, id })

    const currentItem = state.items.find(item => item.id === id)

    if (!currentItem) throw new Error("No such item to update")

    const payload = { ...currentItem, quantity }

    dispatch({
      type: UPDATE_ITEM,
      id,
      payload,
    })

    onItemUpdate && onItemUpdate(payload)
  }

  // Added this to update price when the quantity is updated
  const updateItemPrice = (id, price) => {
    const currentItem = state.items.find(item => item.id === id)

    if (!currentItem) throw new Error("No such item to update")

    const payload = { ...currentItem, price }

    dispatch({
      type: UPDATE_ITEM,
      id,
      payload,
    })

    onItemUpdate && onItemUpdate(payload)
  }

  const removeItem = id => {
    dispatch({ type: REMOVE_ITEM, id })

    onItemRemove && onItemRemove(id)
  }

  const updateCartId = () =>
    dispatch({
      type: UPDATE_CART_ID,
    })

  const emptyCart = () =>
    dispatch({
      type: EMPTY_CART,
    })

  const closeCart = () =>
    dispatch({
      type: CLOSE_CART,
    })

  useEffect(() => {
    if (state.isOpen === false) {
      isBrowser && window.localStorage.removeItem("ie-cart")
    }
    saveCart(JSON.stringify(state))
  }, [state.isOpen])

  const getItem = id => state.items.find(i => i.id === id)

  const inCart = id => state.items.some(i => i.id === id)

  return (
    <CartContext.Provider
      value={{
        ...state,
        getItem,
        inCart,
        addItem,
        updateItem,
        updateItemQuantity,
        updateItemPrice,
        removeItem,
        emptyCart,
        closeCart,
        updateCartId,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}
