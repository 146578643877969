import React, { useContext } from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import UserContext from "../../../context/UserContext"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import Loading from "../../../components/Shared/Loading"
import Error from "../../../components/Shared/Error"
import SignBackIn from "../../SignBackIn"
import Container from "@material-ui/core/Container"

import MenuSection from "./MenuSection"
import SaveCart from "../UserCart/SaveCart"
import Header from "./Header"
import Alerts from "./Alerts"

const UserHome = ({ classes }) => {
  const { state, dispatch } = useContext(UserContext)
  const { loading, error, data } = useQuery(MY_ACCOUNT_QUERY, {
    onError: error => {
      dispatch({ type: "SET_LOGOUT" })
      return <SignBackIn />
    },
  })
  if (loading) return <Loading />
  if (error) return <SignBackIn />
  // if (data === undefined) return "DATA UNDEFINED"
  const me = data.me
  const uid = data.me.person.uid
  const name = data.me.person.firstName

  return (
    <>
      <div className={classes.root}>
        <Container maxWidth="lg">
          <Header name={name} />
          <SaveCart uid={uid} />
          <Alerts />
          <MenuSection me={me} />
        </Container>
      </div>
    </>
  )
}

const MY_ACCOUNT_QUERY = gql`
  query {
    me {
      email
      isVerified
      person {
        uid
        firstName
        lastName
      }
    }
  }
`

const styles = theme => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(1, 0, 0),
  },
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  grid: {
    marginTop: theme.spacing(2),
  },
})

export default withStyles(styles)(UserHome)
