import React, { createContext } from "react"

// const SortContext = createContext({
//     search: "",
//     first: 10,
//     skip: 0,

// })

const SortContext = createContext({
  search: "",
  pageSize: 10,
  page: 1,
  recentFirst: true,
})

export default SortContext
