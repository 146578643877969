import React, { useState, useContext, Fragment } from "react"
import clsx from "clsx"
import { navigate } from "gatsby"
import withStyles from "@material-ui/core/styles/withStyles"
import gql from "graphql-tag"
import { Link } from "gatsby"
import { useQuery, useMutation } from "@apollo/client"
import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Grid,
  Typography,
} from "@material-ui/core"

// import FolderIcon from "@material-ui/icons/Folder"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
// import { green, purple } from "@material-ui/core/colors"
// import Table from "@material-ui/core/Table"
// import TableBody from "@material-ui/core/TableBody"
// import TableCell from "@material-ui/core/TableCell"
// import TableContainer from "@material-ui/core/TableContainer"
// import TableHead from "@material-ui/core/TableHead"
// import TableRow from "@material-ui/core/TableRow"

import { Alert, AlertTitle } from "@material-ui/lab"

const ItemTitleCard = ({
  classes,
  item,
  expandedPrePro,
  setExpandedPrePro,
}) => {
  //   const { state, dispatch } = useContext(UserContext)
  const [showIssues, setShowIssues] = useState(false)

  // Filter for sample or art proof awaiting customer approval
  const activeProofs =
    item.sampleProofItem &&
    item.sampleProofItem
      .filter(i => i.status.id == 2 || i.status.id == 3)
      .filter(i => i.closed === false)
  //  item.sampleProofItem
  //     ? item.sampleProofItem.filter(i => i.status.id === 2 || i.status.id === 3)
  //     : false
  // const needsAttention = item.sampleProofItem
  //   ? item.sampleProofItem.map(i => i.closed).includes(false)
  //   : false
  const sampleProofs = item.sampleProofItem ? item.sampleProofItem : []
  const handleFocusPrePro = () => {
    setShowIssues(!showIssues)
    setExpandedPrePro(true)
  }
  return (
    <div className={classes.root}>
      <Card square className={classes.paper} variant="outlined">
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.title}>
              {item.name ? item.name : "unnamed item"}
            </Typography>
            <Typography className={classes.category}>
              {item.category && item.category.name
                ? item.category.name
                : "unspecified category"}
            </Typography>
            <Box ml={1}>
              <Typography className={classes.isPrivate}>
                {item.isPrivate ? "Private Custom Item" : ""}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            {activeProofs && activeProofs.length > 0 && (
              <Box pt={2} pb={2}>
                <Alert
                  variant="outlined"
                  severity="info"
                  action={
                    <Button
                      color="inherit"
                      size="small"
                      onClick={handleFocusPrePro}
                    >
                      {showIssues ? "Hide" : "View"}
                    </Button>
                  }
                >
                  <AlertTitle variant="caption">
                    Your attention is required
                  </AlertTitle>
                </Alert>
              </Box>
            )}
          </Grid>
        </Grid>
      </Card>
      <Collapse in={showIssues}>
        <Card
          square
          className={clsx(classes.paper, classes.issues)}
          variant="outlined"
        >
          <CardContent>
            {activeProofs &&
              activeProofs.map(sampleProof => {
                const proofId = sampleProof.id
                const statusId = sampleProof.status.id
                const statusName = sampleProof.status.name

                return (
                  <Box key={proofId} mt={2}>
                    <Alert
                      variant="outlined"
                      severity="info"
                      action={
                        <Button
                          color="inherit"
                          size="small"
                          variant="outlined"
                          component={Link}
                          endIcon={<ArrowForwardIcon />}
                          to={`./proof/${proofId}`}
                          state={{ proofId: proofId }}
                          className={classes.sampleList}
                        >
                          {statusId == 2
                            ? "View Proof"
                            : statusId == 3
                            ? "View Sample"
                            : "View Sample/Proof"}
                          {proofId}
                        </Button>
                      }
                    >
                      <AlertTitle variant="caption">
                        Sample proof # {proofId}
                        <br />
                        {statusName}
                      </AlertTitle>
                    </Alert>
                  </Box>
                )
              })}
          </CardContent>
        </Card>
      </Collapse>
    </div>
  )
}

const styles = theme => ({
  root: {
    margin: theme.spacing(0, 0.5, 0.5, 0.5),
    minWidth: 225,
  },
  title: { fontSize: "22px", fontWeight: "bold" },
  category: {
    fontSize: "17px",
    color: theme.palette.primary.main,
  },
  isPrivate: {
    fontSize: "14px",
    // fontWeight: "light",
    color: theme.palette.secondary.main,
    textTransform: "uppercase",
  },

  paper: {
    padding: theme.spacing(2),
    boxShadow: "none",
    borderRadius: "15px",
  },
  issues: {
    marginTop: theme.spacing(2),
  },
})

export default withStyles(styles)(ItemTitleCard)
