import React, { useState, useContext, useEffect } from "react"
import { Box, Button, Grid, SvgIcon, Typography } from "@material-ui/core"
import moment from "moment"
import withStyles from "@material-ui/core/styles/withStyles"
import UserContext from "../../../context/UserContext"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import { useSiteMetadata } from "../../utils/hooks/useSiteMetadata"
import { Alert, AlertTitle } from "@material-ui/lab"
import { Link } from "gatsby"
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined"
import Loading from "../../utils/Loading"
import Error from "../../utils/Error"

import {
  Bell as BellIcon,
  Camera as CameraIcon,
  CheckSquare as CheckSquareIcon,
  Crop as CropIcon,
  DollarSign as DollarSignIcon,
  Truck as SupplyIcon,
  MessageSquare as MessageSquareIcon,
} from "react-feather"
import { navigate } from "@reach/router"

const Alerts = ({ classes }) => {
  const { state, dispatch } = useContext(UserContext)
  // const [alerts, setAlerts] = useState([])
  const alerts = state.alerts
  const page = 1
  const pageSize = 20
  const search = ""
  const { sbuId } = useSiteMetadata()
  const [isActiveFilter, setIsActiveFilter] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [activeAlert, setActiveAlert] = useState("")

  useEffect(() => {
    if (activeAlert) {
      handleClose()
    }
  }, [activeAlert])

  const handleClose = () => {
    setIsSubmitting(true)
    console.log(activeAlert)
    const selAlertId = activeAlert.id
    const updatedAlerts = alerts.filter(a => a.id !== selAlertId)
    // setAlerts(updatedAlerts)
    dispatch({ type: "SET_ALERTS", payload: updatedAlerts })

    dispatch({ type: "SET_ALERT_COUNT", payload: updatedAlerts.length })
    deactivateAlert()
  }

  const { data, loading, error } = useQuery(GET_APP_ALERTS, {
    variables: {
      page: page,
      pageSize: pageSize,
      search: search,
      sbuId: sbuId,
      isActiveFilter: isActiveFilter,
    },
    onCompleted: data => {
      const alertCount = data.myAppAlerts.count
      dispatch({ type: "SET_ALERTS", payload: data.myAppAlerts.objects })
      // setAlerts(data.myAppAlerts.objects)
      dispatch({ type: "SET_ALERT_COUNT", payload: alertCount })

      //   console.log(data.myAppAlerts.count)
      //   console.log(data)
    },
  })

  const [deactivateAlert] = useMutation(DEACTIVATE_MY_APP_ALERT, {
    variables: {
      alertId: activeAlert.id,
    },
    onCompleted: data => {
      setActiveAlert("")
      setIsSubmitting(false)
      console.log("deactivated")
      console.log(data)
    },
  })
  if (loading) return <Loading />
  if (error) return <Error />
  return (
    <>
      {alerts &&
        alerts.map(al => {
          //   const linkPath = alert.alertPath ? (
          //     <Link to={alert.alertPath}>{alert.alertPath}</Link>
          //   ) : (
          //     ""
          //   )
          const categoryId = al.category.id ? al.category.id : null
          const iconSwitch = categoryId => {
            switch (categoryId) {
              case "1":
                return <CheckSquareIcon />
              case "2":
                return <SupplyIcon />
              case "3":
                return <CameraIcon />
              case "4":
                return <CropIcon />
              case "5":
                return <MessageSquareIcon />
              case "6":
                return <DollarSignIcon />
              default:
                return <BellIcon />
            }
          }
          return (
            <div key={al.id} className={classes.root}>
              <Alert
                variant="outlined"
                icon={iconSwitch(categoryId)}
                severity={
                  al.severity === "INFORMATION"
                    ? "info"
                    : al.severity === "ERROR"
                    ? "error"
                    : al.severity === "WARNING"
                    ? "warning"
                    : al.severity === "SUCCESS"
                    ? "success"
                    : "info"
                }
                action={
                  <>
                    <Button
                      color="inherit"
                      size="small"
                      disabled={isSubmitting}
                      onClick={() =>
                        navigate(al.alertPath ? al.alertPath : "/my")
                      }
                    >
                      VIEW
                    </Button>
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => setActiveAlert(al)}
                      disabled={isSubmitting}
                    >
                      DISMISS
                    </Button>
                  </>
                }

                //   action={
                //     <Button color="inherit" size="small">
                //       UNDO
                //     </Button>
                //   }
              >
                <AlertTitle>Action required</AlertTitle>
                {al.alertMessage}
              </Alert>
              <Typography variant="caption">
                {moment(al.sentDate).format("MMMM Do YYYY, h:mm a")}
              </Typography>
            </div>
          )
        })}
    </>
  )
}

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(3),
  },
})

const GET_APP_ALERTS = gql`
  query (
    $sbuId: Int
    $search: String
    $page: Int
    $pageSize: Int
    $isActiveFilter: Boolean
  ) {
    myAppAlerts(
      sbuId: $sbuId
      search: $search
      page: $page
      pageSize: $pageSize
      isActiveFilter: $isActiveFilter
    ) {
      page
      pages
      hasNext
      hasPrev
      count
      objects {
        id
        alertMessage
        sentDate
        severity
        isActive
        alertPath
        category {
          id
          name
          description
        }
      }
    }
  }
`

const DEACTIVATE_MY_APP_ALERT = gql`
  mutation ($alertId: Int!) {
    deactivateMyAppAlert(alertId: $alertId) {
      appAlert {
        id
      }
    }
  }
`
export default withStyles(styles)(Alerts)
