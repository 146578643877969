import React, { createContext } from "react"

const MessagesContext = createContext({
  search: "",
  pageSize: 10,
  page: 1,
  sidebarOpen: false,
  starredFilter: false,
  followingFilter: false,
  hasUpdateFilter: false,
  excludeArchivedFilter: true,
  archivedFilter: false,
  newInvitesFilter: false,
  conversationsCount: 1,
  archivedCount: 0,
  updateCount: 0,
  starCount: 0,
  followingCount: 0,
  invitesCount: 0,
  selectedConversationId: "",
  panel: "conversationList",
  latestMessageUuid: "",
})

export default MessagesContext
