import React, { useState, useContext, Fragment } from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import { Link } from "gatsby"

import Button from "@material-ui/core/Button"
import { useCart } from "../../UserCart/Cart"
import Paper from "@material-ui/core/Paper"

import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"

import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"

import ListItemText from "@material-ui/core/ListItemText"
import { Alert, AlertTitle } from "@material-ui/lab"
import { ShoppingCart as ShoppingCartIcon } from "react-feather"

const UserItemPrices = ({ classes, item }) => {
  const [itemQty, setItemQty] = useState({})
  const [success, setSuccess] = useState(false)
  const { addItem } = useCart()

  const id = item.id ? item.id : undefined
  const name = item.name ? item.name : undefined
  const prices = item.itemPrice ? item.itemPrice : undefined
  const weightUnits =
    item.specItem && item.specItem.weightUnits
      ? item.specItem.weightUnits
      : undefined
  const weightEach =
    item.specItem && item.specItem.unitWeightEach
      ? item.specItem.unitWeightEach
      : undefined

  function financial(x) {
    return Number.parseFloat(x).toFixed(2)
  }
  function calcTotal(a, b) {
    //Return the sum
    return (a * b).toFixed(2)
  }

  function handleAddToCart(prices) {
    const price = Number.parseFloat(`${prices.price}`)
    const qty = Number.parseFloat(`${prices.minQuantity}`)

    const cartItem = {
      id: Number.parseFloat(`${id}`),
      name: `${name}`,
      price: price,
      quantity: qty,
      weightEach: weightEach,
      weightUnits: weightUnits,
    }
    addItem(cartItem)
    setSuccess(true)
  }

  return (
    <>
      <div className={classes.root}>
        <Paper elevation={0} className={classes.paper} variant="outlined">
          <Typography className={classes.orderNow} gutterBottom>
            Order Now
          </Typography>
          {item.pricingModel && item.pricingModel.id == 1 ? (
            <>
              <Typography variant="body1" gutterBottom>
                This item uses quantity-based pricing. Select an order quantity
                to add the item to your cart. You can change the quantity in
                your cart.
              </Typography>
              <Typography variant="subtitle1">
                Need additional price breakpoints?{" "}
                <Link to="/my/service" className={classes.link}>
                  Send us a message
                </Link>{" "}
                to request pricing at your desired quantity.
              </Typography>
            </>
          ) : item.pricingModel && item.pricingModel.name ? (
            <Typography variant="body1" gutterBottom>
              Pricing Model: {item.pricingModel.name}
            </Typography>
          ) : (
            ""
          )}
          <>
            {success === true && (
              <Alert
                variant="outlined"
                severity="success"
                onClose={() => {
                  setSuccess(false)
                }}
              >
                <AlertTitle>Item added to cart</AlertTitle>
                <Button
                  size="small"
                  startIcon={<ShoppingCartIcon />}
                  component={Link}
                  to={"/my/cart/"}
                >
                  Go to Cart
                </Button>
              </Alert>
            )}
            <List>
              {prices &&
                prices.map(p => {
                  const qty = p.minQuantity
                  const price = financial(p.price)
                  return (
                    <Fragment key={`fragment ${p.id}`}>
                      <Divider key={`divider ${p.id}`} />
                      <ListItem
                        key={p.id}
                        button
                        onClick={e => handleAddToCart(p)}
                        className={classes.pricingLink}
                      >
                        <ListItemText
                          key={`text ${p.id}`}
                          primary={`${qty} pcs for ${price} each`}
                        />
                        <ListItemAvatar key={`avatar ${p.id}`} edge="end">
                          <ShoppingCartIcon />
                        </ListItemAvatar>
                      </ListItem>
                    </Fragment>
                  )
                })}
            </List>
            <Divider />
          </>
        </Paper>
      </div>
    </>
  )
}

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  orderNow: {
    fontSize: "23px",
    fontWeight: "bold",
  },
  pricingLink: {
    "&:hover": {
      textDecoration: "none",
      backgroundColor: theme.palette.action.hoverLight,

      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    boxShadow: "none",
    borderRadius: "15px",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.link,
  },
})

export default withStyles(styles)(UserItemPrices)
