import React from "react"
import {
  AccordionActions,
  ButtonBase,
  CardActions,
  Typography,
} from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import clsx from "clsx"

const BigBlackButton = ({ classes, buttonText, action, outlined }) => {
  return (
    <div className={classes.root}>
      <ButtonBase
        className={clsx(
          classes.bigButton,
          outlined ? classes.outlinedButton : classes.contrastButton
        )}
        onClick={action}
      >
        <Typography
          component="h1"
          className={classes.boxTitle}
          color="inherit"
          gutterBottom
        >
          {buttonText}
        </Typography>
      </ButtonBase>
    </div>
  )
}
const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: 1,
  },
  bigButton: {
    width: "100%",
    border: "2px solid #000000",
    textTransform: "uppercase",
    fontWeight: "bold",
    padding: theme.spacing(1),
    marginLeft: "auto",
    marginRight: "auto",
  },
  outlinedButton: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.black,
    "&:hover": {
      border: "2px solid #2d2d2d",
      color: theme.palette.common.almostBlack,
    },
  },
  contrastButton: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.common.almostBlack,
      border: "2px solid #2d2d2d",
    },
  },
})
export default withStyles(styles)(BigBlackButton)
