import React, { useState, useEffect, useContext, useReducer } from "react"
import UserContext from "./UserContext"
import UserReducer from "./UserReducer"

// IMPORTANT The UserProvider only functions to persist login state. Do not store personal data or tokens in localStorage.
const UserProvider = props => {
  const [localState, setLocalState] = useState("")
  const initialState = useContext(UserContext)
  // const localState = JSON.parse(localStorage.getItem("userUx"))
  const [state, dispatch] = useReducer(UserReducer, localState || initialState)

  useEffect(() => {
    setLocalState(JSON.parse(localStorage.getItem("userUx")))
  }, [])

  useEffect(() => {
    localStorage.setItem("userUx", JSON.stringify(state))
  }, [state])

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {props.children}
    </UserContext.Provider>
  )
}
export default UserProvider
