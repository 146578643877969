import React, { useState, useContext } from "react"
import { Link } from "gatsby"

import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Hidden,
  IconButton,
  Paper,
  SvgIcon,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../Shared/Loading"
import Error from "../../../Shared/Error"
import UserContext from "../../../../context/UserContext"
import UserItemImages from "./UserItemImages"
import UserItemPrices from "./UserItemPrices"
import ItemTitleCard from "./ItemTitleCard"
import UserItemSpecs from "./UserItemSpecs"
import UserItemPreproduction from "./UserItemPreproduction"
import { Menu as MenuIcon } from "react-feather"
import { useSiteMetadata } from "../../../utils/hooks/useSiteMetadata"

const UserPrivateItemDetails = ({ classes, id }) => {
  const { state, dispatch } = useContext(UserContext)
  const { sbuId } = useSiteMetadata()
  const [item, setItem] = useState("")
  const [specs, setSpecs] = useState("")
  const [itemImage, setItemImage] = useState("")
  const [weight, setWeight] = useState(null)
  const [weightUnits, setWeightUnits] = useState("")
  const [expandedPrePro, setExpandedPrePro] = useState(false)

  const { data, loading, error } = useQuery(MY_PRIVATE_ITEM_DETAILS_QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      id: id,
      businessUnitId: sbuId,
    },
    onCompleted: data => {
      setItem(data.myPrivateItem.item)
      setSpecs(data.myPrivateItem.item.specItem)
      setItemImage(data.myPrivateItem.item.itemImage)
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <>
      <div className={classes.root}>
        <Container maxWidth="lg">
          <Grid container alignItems="flex-start" spacing={3}>
            <Grid item xs={12} container justify="space-between">
              <Grid item>
                <Box mt={2} pb={1}>
                  <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                  >
                    <Link
                      variant="body1"
                      className={classes.link}
                      to="/my/items"
                      component={Link}
                    >
                      My Items
                    </Link>

                    <Typography variant="body1" color="textPrimary">
                      {item.name}
                    </Typography>
                  </Breadcrumbs>
                </Box>
              </Grid>
              <Hidden mdUp>
                <Grid item>
                  <IconButton
                    onClick={() => dispatch({ type: "TOGGLE_MOBILE_NAV" })}
                  >
                    <SvgIcon fontSize="small" color="secondary">
                      <MenuIcon />
                    </SvgIcon>
                  </IconButton>
                </Grid>
              </Hidden>
            </Grid>

            <Grid item xs={12}>
              <ItemTitleCard
                item={item}
                expandedPrePro={expandedPrePro}
                setExpandedPrePro={setExpandedPrePro}
              />
            </Grid>
            <Grid item container direction="column" xs={12} md={4}>
              {itemImage.length > 0 && (
                <Grid item xs={12}>
                  <UserItemImages itemImage={itemImage} />
                </Grid>
              )}
              {specs && (
                <Grid item xs={12}>
                  <UserItemSpecs specs={specs} />
                </Grid>
              )}
            </Grid>

            <Grid
              item
              container
              alignItems="flex-start"
              // spacing={3}
              xs={12}
              md={8}
            >
              <Grid item xs={12}>
                <UserItemPrices item={item} />
                <Box mt={2}>
                  <UserItemPreproduction
                    item={item}
                    expandedPrePro={expandedPrePro}
                    setExpandedPrePro={setExpandedPrePro}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  )
}
const MY_PRIVATE_ITEM_DETAILS_QUERY = gql`
  query ($id: Int!, $businessUnitId: Int!) {
    myPrivateItem(id: $id, businessUnitId: $businessUnitId) {
      item {
        id
        name
        category {
          name
        }
        dateAdded
        dateModified
        isActive
        isPrivate
        pricingModel {
          id
          name
          description
        }
        itemPrice {
          id
          minQuantity
          maxQuantity
          price
        }
        itemImage {
          id
          image
          carouselImageUrl
          imageUrl
          title
          note
          uploadedAt
        }
        sampleProofItem {
          id
          version
          closed
          closedTimestamp
          status {
            id
            name
          }
        }
        specItem {
          specId
          summary
          unitWeightEach
          weightUnits
        }
      }
      itemOwner {
        uid
        firstName
        lastName
      }
    }
  }
`

const styles = theme => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(1, 0, 0),
  },
  // container: {
  //   display: "grid",
  // },
  itemImage: {
    height: 420,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.common.black,
  },
})

export default withStyles(styles)(UserPrivateItemDetails)
