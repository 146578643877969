import React, { useState, useContext, useEffect } from "react"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"

import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"

import TextField from "@material-ui/core/TextField"
// import FormControlLabel from "@material-ui/core/FormControlLabel"

import Link from "@material-ui/core/Link"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import { Alert, AlertTitle } from "@material-ui/lab"
import Loading from "../Shared/Loading"
import Error from "../Shared/Error"
import Copyright from "../utils/Copyright"

import UserContext from "../../context/UserContext"
import { navigate } from "gatsby"

// import uiLocalStorageHook from "./UiLocalStorageHook"
// import { LoggedInContext } from "../../context/LoggedInContext"

const SignBackIn = ({ classes }) => {
  const { state, dispatch } = useContext(UserContext)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const [handleSubmit, { loading, error }] = useMutation(LOGIN_MUTATION, {
    variables: {
      email: email,
      password: password,
    },
    onCompleted: data => {
      setEmail("")
      setPassword("")
      dispatch({ type: "SET_LOGIN" })
    },
  })

  return (
    <div>
      <Container maxWidth="xs">
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign back in
          </Typography>

          <form
            className={classes.form}
            noValidate
            onSubmit={event => {
              event.preventDefault()
              handleSubmit(event)
            }}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={event => setEmail(event.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={event => setPassword(event.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loading || !email.trim() || !password.trim()}
            >
              {loading ? "Just a sec" : "Sign Back In"}
            </Button>
            <Grid container>
              {/* <Grid item xs>
                <Link
                  component="button"
                  onClick={e => setForgotPassword(true)}
                  variant="body2"
                >
                  Forgot password?
                </Link>
              </Grid> */}

              {error && (
                // <Grid item>
                <div className={classes.alert}>
                  <Alert variant="outlined" severity="warning">
                    {`${error.graphQLErrors[0].message}`}
                  </Alert>
                </div>
                // </Grid>
              )}
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </div>
  )
}
const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#ffffff",
  },
  alert: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    display: "flex",
    flexDirection: "column",
  },
})
const LOGIN_MUTATION = gql`
  mutation tokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      __typename
    }
  }
`

export default withStyles(styles)(SignBackIn)
