export default function reducer(state, action) {
  switch (action.type) {
    case "SET_INQUIRY_UUID":
      return {
        ...state,
        inquiryUuid: action.payload,
      }

    case "TOGGLE_INQUIRY_SUBMIT":
      return {
        inquirySubmit: !state.inquirySubmit,
      }

    case "TOGGLE_SHOW_MENU":
      return {
        ...state,
        showMenu: !state.showMenu,
      }
    case "SET_SHOW_MENU":
      return {
        ...state,
        showMenu: action.payload,
      }
    case "SET_SHOW_CONFIRM":
      return {
        ...state,
        showConfirm: action.payload,
      }
    case "SET_USER_QUOTE":
      return {
        ...state,
        uQ: action.payload,
      }

    case "SET_ITEM_SWITCH":
      return {
        ...state,
        itemSwitch: action.payload,
      }
    case "SET_NAME":
      return {
        ...state,
        name: action.payload,
      }
    case "SET_ITEMS":
      return {
        ...state,
        items: action.payload,
      }

    default:
      return state
  }
}
