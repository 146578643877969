import React, { createContext } from "react"

const InquiryContext = createContext({
  inquiryUuid: "",
  showMenu: false,
  showConfirm: false,
  itemSwitch: "inactive",
  inquirySubmit: false,
  uQ: false,
  name: "",
  items: [],
})

export default InquiryContext
