import React, { useEffect, useContext, useReducer } from "react"
import InquiryContext from "./InquiryContext"
import InquiryReducer from "./InquiryReducer"

const InquiryProvider = props => {
  const initialState = useContext(InquiryContext)

  // Not using localstorage
  const [state, dispatch] = useReducer(InquiryReducer, initialState)

  // Using LocalStorage
  // const localState = JSON.parse(localStorage.getItem("inq"))
  // const [state, dispatch] = useReducer(
  //   InquiryReducer,
  //   localState || initialState
  // )
  // useEffect(() => {
  //   localStorage.setItem("inq", JSON.stringify(state))
  // }, [state])

  return (
    <InquiryContext.Provider value={{ state, dispatch }}>
      {props.children}
    </InquiryContext.Provider>
  )
}

export default InquiryProvider
