import React from "react"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import Loading from "../utils/Loading"
import CreatePerson from "./CreatePerson"

export const FindPerson = ({ email, firstName, lastName }) => {
  const { loading, error, data } = useQuery(FIND_PERSON_QUERY, {
    variables: {
      emailAccount: { email },
    },
  })

  if (loading) return <Loading />
  if ({ data } == null)
    return <CreatePerson firstName={firstName} lastName={lastName} />
  if (error) console.log("error finding email")

  return (
    <div>
      <h1>good</h1>
    </div>
  )
}
const FIND_PERSON_QUERY = gql`
  query($emailAccount: String!) {
    emailAccount(emailAccount: $emailAccount) {
      emailAccount
      person {
        id
        firstName
        lastName
      }
    }
  }
`
export default FindPerson
