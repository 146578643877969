import React, { useState, useEffect, useContext, useReducer } from "react"
import MessagesContext from "./MessagesContext"
import MessagesReducer from "./MessagesReducer"

const MessagesProvider = props => {
  const initialState = useContext(MessagesContext)
  const [localState, setLocalState] = useState("")
  const isBrowser = () => typeof window !== "undefined"
  useEffect(() => {
    isBrowser && setLocalState(JSON.parse(window.localStorage.getItem("cnv")))
  }, [])

  const [state, dispatch] = useReducer(
    MessagesReducer,
    localState || initialState
  )

  useEffect(() => {
    isBrowser && window.localStorage.setItem("cnv", JSON.stringify(state))
  }, [state])

  return (
    <MessagesContext.Provider value={{ state, dispatch }}>
      {props.children}
    </MessagesContext.Provider>
  )
}

export default MessagesProvider
