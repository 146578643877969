import React, { useState, useContext } from "react"
import { Link } from "gatsby"
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Hidden,
  IconButton,
  Paper,
  SvgIcon,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../utils/Loading"
import Error from "../../../utils/Error"
import UserContext from "../../../../context/UserContext"

const UserOrderItemDetails = ({ classes, id }) => {
  const { state, dispatch } = useContext(UserContext)
  const [item, setItem] = useState("")
  const [specs, setSpecs] = useState("")
  const [itemImage, setItemImage] = useState("")
  const [weight, setWeight] = useState(null)
  const [weightUnits, setWeightUnits] = useState("")
  const [expandedPrePro, setExpandedPrePro] = useState(false)
  const { data, loading, error } = useQuery(MY_ORDER_ITEM_DETAILS_QUERY, {
    fetchPolicy: "no-cache",
    variables: { id: id },
    onCompleted: data => {
      setItem(data.myOrderItem)
      setSpecs(data.myOrderItem.specItem)
      setItemImage(data.myOrderItem.itemImage)
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <div>
      <div>Order Item Details Page</div>
      <div>{JSON.stringify(data)}</div>
    </div>
  )
}

const styles = theme => ({
  root: {},
})

const MY_ORDER_ITEM_DETAILS_QUERY = gql`
  query($id: Int) {
    myOrderItem(id: $id) {
      id
      name
      category {
        name
      }
      dateAdded
      dateModified
      isActive
      isPrivate
      pricingModel {
        id
        name
        description
      }
      itemPrice {
        id
        minQuantity
        maxQuantity
        price
      }
      itemImage {
        id
        image
        carouselImageUrl
        imageUrl
        title
        note
        uploadedAt
      }
      sampleProofItem {
        id
        version
        closed
        closedTimestamp
        status {
          id
          name
        }
      }
      specItem {
        specId
        summary
        unitWeightEach
        weightUnits
      }
    }
  }
`

export default withStyles(styles)(UserOrderItemDetails)
