import React, { useState, Fragment } from "react"
import clsx from "clsx"
import withStyles from "@material-ui/core/styles/withStyles"
import {
  Box,
  Card,
  CardHeader,
  Container,
  Divider,
  Typography,
} from "@material-ui/core"

import CardActions from "@material-ui/core/CardActions"
import IconButton from "@material-ui/core/IconButton"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Collapse from "@material-ui/core/Collapse"

const UserItemSpecs = ({ classes, specs }) => {
  const [expanded, setExpanded] = useState(true)

  // const item = privateItem.item
  const itemSpec = JSON.parse(specs.summary)
  const sortedSpecs = Object.entries(itemSpec).sort((a, b) =>
    b[0].localeCompare(a[0])
  )

  const measurementFields = ["width", "height", "length", "depth", "thickness"]
  const noteFields = ["notes", "note", "comments", "comment"]
  const specSize = []
  const specDetails = []
  const specNote = []

  for (const [key, value] of sortedSpecs) {
    let measurements = measurementFields.includes(key)
    let notes = noteFields.includes(key)
    let details = !measurements && !notes
    let format = (
      <Fragment key={key}>
        {isNaN(key.charAt(0)) &&
          (key.charAt(0).toUpperCase() + key.slice(1)).replace(/_/g, " ")}
        :{" "}
        {isNaN(value.charAt(0))
          ? value.charAt(0).toUpperCase() + value.slice(1)
          : value}
        <br />
      </Fragment>
    )
    if (measurements) specSize.push(format)
    if (notes) specNote.push(format)
    if (details) specDetails.push(format)
  }

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  return (
    <div className={classes.root}>
      <Card square className={classes.paper} variant="outlined">
        <CardHeader
          title="Specs"
          action={
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          }
        />

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box className={classes.expandedSection}>
            <Typography variant="body1">{specSize}</Typography>
            <Typography variant="caption" gutterBottom>
              * measurements are in inches
            </Typography>
            <Typography variant="body1" gutterBottom>
              {specDetails}
            </Typography>
            <Typography variant="body1">{specNote}</Typography>

            {specs.description}
          </Box>
        </Collapse>
      </Card>
    </div>
  )
}

const styles = theme => ({
  root: {
    margin: theme.spacing(1, 0, 0, 0),
    minWidth: 225,
  },
  title: {},
  paper: {
    padding: theme.spacing(1),
    boxShadow: "none",
    borderRadius: "15px",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  expandedSection: {
    padding: theme.spacing(2),
  },
})

export default withStyles(styles)(UserItemSpecs)
