import React from "react"
import {
  ButtonBase,
  Paper,
  Typography,
  Select,
  FormControl,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "90%",
    paddingLeft: theme.spacing(2),
  },
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  contrastButton: {
    width: "100%",
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    textTransform: "uppercase",
    fontWeight: "bold",
    padding: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.common.almostBlack,
    },
    marginLeft: "auto",
    marginRight: "auto",
  },
  formControl: {
    minWidth: 50,
  },
}))

export default function AddToCartButton({
  quantityOptions,
  onAddToCart,
  qty,
  setQty,
}) {
  const classes = useStyles()
  const handleUpdateQty = event => {
    setQty(event.target.value)
  }

  return (
    <Paper square variant="outlined" component="form" className={classes.root}>
      <FormControl variant="standard" className={classes.formControl}>
        <Select
          native
          disableUnderline
          value={qty}
          onChange={handleUpdateQty}
          label="Qty"
          inputProps={{
            name: "qty",
            id: "qty-select",
          }}
        >
          {quantityOptions.map((qty, index) => (
            <option key={index} value={qty}>
              {qty}
            </option>
          ))}
        </Select>
      </FormControl>
      <ButtonBase className={classes.contrastButton} onClick={onAddToCart}>
        <Typography
          component="h1"
          className={classes.boxTitle}
          color="inherit"
          gutterBottom
        >
          Add to Cart
        </Typography>
      </ButtonBase>
    </Paper>
  )
}
