import React, { useState, useEffect, useContext, useReducer } from "react"
import SortContext from "./SortContext"
import SortReducer from "./SortReducer"

const SortProvider = props => {
  const initialState = useContext(SortContext)
  const [localState, setLocalState] = useState("")
  const [state, dispatch] = useReducer(SortReducer, localState || initialState)

  useEffect(() => {
    setLocalState(JSON.parse(localStorage.getItem("srt")))
  }, [])

  useEffect(() => {
    localStorage.setItem("srt", JSON.stringify(state))
  }, [state])

  return (
    <SortContext.Provider value={{ state, dispatch }}>
      {props.children}
    </SortContext.Provider>
  )
}

export default SortProvider
