import React, { useState, useContext } from "react"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import { Link, navigate } from "gatsby"
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import { makeStyles } from "@material-ui/core/styles"

import UserContext from "../../context/UserContext"
import Loading from "../Shared/Loading"
import Error from "../Shared/Error"
import SignIn from "./SignIn"
import UserHome from "../User/UserHome"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
    backgroundColor: "#ffffff",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const VerifyAccount = () => {
  const { state, dispatch } = useContext(UserContext)
  const [emailSent, setEmailSent] = useState(false)
  const [showButton, setShowButton] = useState(false)
  const [verified, setVerified] = useState(false)
  const isVerified = state.isVerified
  const classes = useStyles()

  const { loading, error, data } = useQuery(CHECK_ME_QUERY, {
    onCompleted: data => {
      let name = data.me.person.firstName
      let initial = name[0]
      dispatch({ type: "SET_VERIFIED", payload: data.me.isVerified })
      setVerified(data.me.isVerified)
      dispatch({ type: "SET_INITIAL", payload: initial })
    },
  })

  if (loading) return <Loading />
  if (error) return <SignIn />

  if (isVerified === true) {
    return <UserHome path="/my" />
  }

  return (
    <>
      <Container maxWidth="xs">
        <div className={classes.paper}>
          {isVerified ? (
            <CircularProgress />
          ) : (
            <>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5" gutterBottom>
                Thanks for Signing Up!
              </Typography>

              <div>
                <div>
                  <Typography component="p" variant="body1" gutterBottom>
                    For your security, please check your email for account
                    verification instructions.
                  </Typography>
                  <Box mt={3}>
                    <Button
                      color="primary"
                      variant="outlined"
                      fullWidth
                      onClick={setShowButton}
                    >
                      Can't find the email?
                    </Button>
                  </Box>
                </div>

                {showButton && (
                  <Box mt={3}>
                    <Button fullWidth color="primary" variant="contained">
                      Get a new verification email
                    </Button>
                  </Box>
                )}
              </div>
            </>
          )}
        </div>
      </Container>
    </>
  )
}
const CHECK_ME_QUERY = gql`
  query {
    me {
      isVerified
      person {
        firstName
        lastName
      }
    }
  }
`
export default VerifyAccount
