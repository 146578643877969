import React, { useState, useContext, useEffect } from "react"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"
import {
  Badge,
  Box,
  Button,
  ButtonBase,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core"

import BigBlackButton from "../DropIn/BigBlackButton"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"

import { withStyles } from "@material-ui/core/styles"

import { Alert, AlertTitle } from "@material-ui/lab"
import Loading from "../Shared/Loading"
import Error from "../Shared/Error"
import Copyright from "../utils/Copyright"
import SignUp from "./SignUp"
import UserHome from "../User/UserHome"
import UserContext from "../../context/UserContext"
import { Link, navigate } from "gatsby"

// import uiLocalStorageHook from "./UiLocalStorageHook"
// import { LoggedInContext } from "../../context/LoggedInContext"

const SignIn = ({ setNewUser, setForgotPassword, classes }) => {
  const { state, dispatch } = useContext(UserContext)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [value, setValue] = useState("login")
  const handleChange = () => {
    console.log("changed")
  }
  const [handleSubmit, { loading, error }] = useMutation(LOGIN_MUTATION, {
    variables: {
      email: email,
      password: password,
    },
    onCompleted: data => {
      setEmail("")
      setPassword("")
      dispatch({ type: "SET_LOGIN" })
    },
  })

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Paper square variant="outlined" className={classes.paper}>
          <Box className={classes.navBox}>
            <ButtonBase
              disableRipple
              onClick={handleSubmit}
              className={classes.activeTab}
            >
              <Typography className={classes.activeText}>Sign In</Typography>
            </ButtonBase>
            <ButtonBase
              disableRipple
              className={classes.inactiveTab}
              onClick={e => setNewUser(true)}
            >
              <Typography className={classes.inactiveText}>
                Create an Account
              </Typography>
            </ButtonBase>
          </Box>
          <div className={classes.formSection}>
            <form
              className={classes.form}
              noValidate
              onSubmit={event => {
                event.preventDefault()
                handleSubmit(event)
              }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={event => setEmail(event.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={event => setPassword(event.target.value)}
              />
              <ButtonBase
                className={classes.contrastButton}
                type="submit"
                disabled={loading || !email.trim() || !password.trim()}
              >
                <Typography
                  component="h1"
                  className={classes.boxTitle}
                  color="inherit"
                  gutterBottom
                >
                  {loading ? "Just a sec" : "Sign In"}
                </Typography>
              </ButtonBase>
              <Grid container>
                <Grid item xs={12}>
                  <ButtonBase
                    disableRipple
                    style={{ width: "100%" }}
                    onClick={e => setForgotPassword(true)}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: "18px",
                        paddingLeft: "auto",
                        paddingRight: "auto",
                        marginTop: "15px",
                      }}
                    >
                      Forgot your password?
                    </Typography>
                  </ButtonBase>
                </Grid>

                {error && (
                  <Grid item xs={12}>
                    <div className={classes.alert}>
                      <Alert variant="outlined" severity="warning">
                        {`${error.graphQLErrors[0].message}`}
                      </Alert>
                    </div>
                  </Grid>
                )}
              </Grid>
            </form>
          </div>
        </Paper>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </div>
  )
}
const styles = theme => ({
  root: {
    marginTop: theme.spacing(7),
    display: "flex",
    flexDirection: "column",
  },
  paper: {
    backgroundColor: "#ffffff",
  },
  formSection: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  alert: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  navBox: {
    height: "60px",
    width: "100%",
    backgroundColor: "#F2F2F2",
    display: "flex",
  },
  activeTab: {
    height: "100%",
    width: "50%",
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontWeight: "bold",
    borderBottom: "5px solid #b71c1c",
    textAlign: "center",
  },
  activeText: {
    color: theme.palette.common.white,
    textTransform: "uppercase",
    fontWeight: "bold",
    textAlign: "center",
    padding: "15px 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  inactiveText: {
    color: theme.palette.common.black,
    textTransform: "uppercase",
    fontWeight: "bold",
    textAlign: "center",
    padding: "15px 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  inactiveTab: {
    height: "100%",
    width: "50%",
    fontWeight: "bold",
    backgroundColor: "#F2F2F2",
  },
  contrastButton: {
    width: "100%",
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    textTransform: "uppercase",
    fontWeight: "bold",
    padding: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.common.almostBlack,
    },
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(2),
  },
})
const LOGIN_MUTATION = gql`
  mutation tokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      __typename
    }
  }
`

export default withStyles(styles)(SignIn)
