import React, { useContext } from "react"
import Grid from "@material-ui/core/Grid"
import UserContext from "../../context/UserContext"
// import { navigate } from "gatsby"
import Layout from "../Layout/layout"
import Sign from "../Sign"

const Error = ({ error }) => {
  const { state, dispatch } = useContext(UserContext)
  // dispatch({ type: "SET_LOGOUT" })
  // if error.message{
  //   error.contains(
  //     "Not Logged In!" || "Please log in" || "logged in" || "does not exist"
  //   ) && dispatch({ type: "SET_LOGOUT" })
  // }
  return (
    <Layout>
      <Sign />
    </Layout>
  )
}

export default Error
