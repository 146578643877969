/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useContext } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core"
import Header from "./Header"

import Footer from "./Footer"
// import "./layout.css"
import CookiesNotification from "./CookiesNotification"
import UserContext from "../../context/UserContext"
// import BottomNavigation from "./BottomNavigation"
// import BottomAppBar from "./BottomAppBar"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },

  space: {
    height: "100px",
    [theme.breakpoints.down("xs")]: {
      height: "60px",
    },
  },
}))

const Layout = ({ children }) => {
  const classes = useStyles()
  const { state } = useContext(UserContext)
  const [isMobileNavOpen, setMobileNavOpen] = useState(false)
  const isLoggedIn = state.isLoggedIn
  const isVerified = state.isVerified
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <header>
        <Header
          siteTitle={data.site.siteMetadata.title}
          onMobileNavOpen={() => setMobileNavOpen(true)}
        />
      </header>
      <div className={classes.space} />
      <main>{children}</main>

      <footer>
        <Footer />
      </footer>
      {/* <BottomNavigation position="fixed" /> */}
      {/* <BottomAppBar position="fixed" /> */}
      <CookiesNotification />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
