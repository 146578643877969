import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"

import { Grid, SvgIcon, Typography, makeStyles } from "@material-ui/core"

import { Home as HomeIcon } from "react-feather"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  welcome: {
    marginTop: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}))

function Header({ className, name, ...rest }) {
  const classes = useStyles()

  return (
    <Grid
      container
      spacing={3}
      justify="space-between"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid item>
        <SvgIcon fontSize="small" color="secondary" className={classes.icon}>
          <HomeIcon />
        </SvgIcon>
        <Typography variant="h5" color="inherit" display="inline">
          Main Menu
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.welcome}
        >
          {name.length > 0 ? `Welcome, ${name}` : "Welcome"}
        </Typography>
      </Grid>
    </Grid>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

export default Header
