import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import withStyles from "@material-ui/core/styles/withStyles"
import Image from "gatsby-image"
import Grid from "@material-ui/core/Grid"

import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"
import CardContent from "@material-ui/core/CardContent"
import ButtonBase from "@material-ui/core/ButtonBase"
import Typography from "@material-ui/core/Typography"

const MenuSection = ({ classes, me }) => {
  const data = useStaticQuery(getImages)
  const ordersIcon = data.orders.childImageSharp.fixed
  const itemsIcon = data.items.childImageSharp.fixed
  const filesIcon = data.files.childImageSharp.fixed
  const cartIcon = data.cart.childImageSharp.fixed
  const profileIcon = data.profile.childImageSharp.fixed
  const serviceIcon = data.service.childImageSharp.fixed
  const gap = { xs: 1, sm: 1.5, lg: 2 }

  const userMenu = [
    {
      id: 1,
      url: "./orders",
      icon: ordersIcon,
      title: "Orders",
      description:
        "Check on your current order status, view previous orders, and track shipments.",
    },
    {
      id: 2,
      url: "./items",
      icon: itemsIcon,
      title: "Items",
      description:
        "View your custom items, check proofs and samples, and add items to your cart.",
    },
    {
      id: 3,
      url: "./files",
      icon: filesIcon,
      title: "Files",
      description: "View, download and upload files to your account.",
    },
    {
      id: 4,
      url: "./cart",
      icon: cartIcon,
      title: "Cart",
      description:
        "View your cart, add or remove items, and proceed to checkout.",
    },
    {
      id: 5,
      url: "./account",
      icon: profileIcon,
      title: "Account",
      description:
        "View and update your address, phone, social media, and email preferences.",
    },
    {
      id: 6,
      url: "./service",
      icon: serviceIcon,
      title: "Service",
      description:
        "Ask questions and chat with customer service reps and designers.",
    },
  ]

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {userMenu.map(menu => (
          <Grid item xs={12} sm={6} lg={4} key={menu.id}>
            <Link to={menu.url} className={classes.cardlink}>
              <Card square className={classes.menuCard}>
                <Grid item>
                  <div className={classes.details}>
                    <CardContent className={classes.content}>
                      <Typography
                        className={classes.title}
                        component="h5"
                        variant="h5"
                      >
                        {menu.title}
                      </Typography>
                      <Typography
                        className={classes.subheader}
                        variant="subtitle1"
                        color="textSecondary"
                      >
                        {menu.description}
                      </Typography>
                    </CardContent>
                  </div>
                </Grid>
                <Grid item>
                  <Box mt={2} className={classes.image}>
                    <Image className={classes.img} fixed={menu.icon} />
                  </Box>
                </Grid>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

const styles = theme => ({
  root: {
    display: "flex",
    paddingBottom: theme.spacing(2),
    height: "100%",
  },
  menuCard: {
    display: "flex",
    minHeight: 225,
    borderRadius: "15px",
  },
  title: {
    fontSize: "1.25rem",
    color: theme.palette.primary.main,
    underline: "none",
  },
  subheader: {
    fontSize: "0.95rem",
    color: theme.palette.primary.light,
    marginTop: theme.spacing(1.5),
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 ",
    alignItems: "left",
  },
  cover: {
    width: 100,
  },
  icon: {},
  image: {
    width: 110,
    height: 110,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  cardlink: {
    textDecoration: "none",
  },
})

const getImages = graphql`
  {
    cart: file(relativePath: { eq: "cart.png" }) {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    orders: file(relativePath: { eq: "orders.png" }) {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    profile: file(relativePath: { eq: "profile.png" }) {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    files: file(relativePath: { eq: "files.png" }) {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    items: file(relativePath: { eq: "items.png" }) {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    service: file(relativePath: { eq: "service.png" }) {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default withStyles(styles)(MenuSection)
