import React, { useContext } from "react"
import { Badge, IconButton, Tooltip } from "@material-ui/core"

import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"
import UserContext from "../../../context/UserContext"
import { useCart } from "../../User/UserCart/Cart"
import { navigate } from "gatsby"

const HeaderCartButton = () => {
  const { state, dispatch } = useContext(UserContext)
  const isLoggedIn = state.isLoggedIn
  const { totalUniqueItems } = useCart()

  const handleNavigate = () => {
    isLoggedIn ? navigate("/my/cart") : navigate("/cart")
    console.log(isLoggedIn)
  }
  return (
    <>
      <Tooltip title="Cart">
        <IconButton
          aria-label="show cart items"
          color="inherit"
          onClick={handleNavigate}
        >
          <Badge badgeContent={totalUniqueItems} color="secondary">
            <ShoppingCartIcon style={{ fontSize: "22px" }} />
          </Badge>
        </IconButton>
      </Tooltip>
    </>
  )
}

export default HeaderCartButton
