import React, { useContext, useReducer, useEffect } from "react"
import { ApolloProvider } from "@apollo/client"

import { CartProvider } from "../components/User/UserCart/Cart"
import { client } from "./client"
import { v4 as uuidv4 } from "uuid"
// import { siteMetadata } from "../../gatsby-config"
// import UserReducer from "../context/UserReducer"
// import UserContext from "../context/UserContext"

import UserProvider from "../context/UserProvider"
import InquiryProvider from "../components/Inquiry/context/InquiryProvider"
import SortProvider from "../context/SortProvider"
import MessagesProvider from "../components/User/UserMessages/context/MessagesProvider"
// MaterialUI theme generator
import CssBaseline from "@material-ui/core/CssBaseline"
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme({
  palette: {
    common: {
      black: "#070809",
      white: "#fff",
      almostBlack: "#181C20",
      blue: "#2789ca",
    },
    background: {
      paper: "#fff",
      default: "#FCFCFC",
      dark: "#e9e9e9",
      highlight: "#000000",
    },
    // #c6ccd3
    primary: {
      light: "#484848",
      main: "#21252B",
      dark: "#333333",
      contrastText: "#FFFFFF",
    },
    secondary: {
      light: "#f05545",
      main: "#b71c1c",
      dark: "#7f0000",
      contrastText: "#fff",
    },
    link: "#248bd7",
    error: {
      light: "rgba(158, 158, 158, 1)",
      main: "rgba(193, 165, 123, 1)",
      dark: "rgba(158, 158, 158, 1)",
      contrastText: "#fff",
    },
    text: {
      primary: "#21252B",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
    action: {
      // hover: "#F2F2F2",
      hover: "#333333",
      hoverLight: "#f0f0f0",
      hoverDark: "#333333",
    },
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  },
  breakpoints: {
    values: {
      xxs: 0,
      xs: 350,
      sm: 600,
      md: 960,
      // md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiTextField: {
      borderRadius: 1,
    },
  },
})

// This wraps all the providers around the entire site

export const wrapRootElement = ({ element }) => (
  <UserProvider>
    <ApolloProvider client={client}>
      <MessagesProvider>
        <CartProvider id={uuidv4()}>
          <SortProvider>
            <InquiryProvider>
              <MuiThemeProvider theme={theme}>
                <CssBaseline />
                {element}
              </MuiThemeProvider>
            </InquiryProvider>
          </SortProvider>
        </CartProvider>
      </MessagesProvider>
    </ApolloProvider>
  </UserProvider>
)
