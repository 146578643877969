import React, { useState, useContext } from "react"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"

import {
  Box,
  Button,
  Container,
  ButtonBase,
  TextField,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"

import { Alert } from "@material-ui/lab"
import FindPerson from "./FindPerson"
import UserContext from "../../context/UserContext"
import Copyright from "../utils/Copyright"
import { navigate } from "gatsby"
import useDidMountEffect from "../utils/hooks/useDidMountEffect"
import VerifyAccount from "./VerifyAccount"
import Sign from "./index"
import { useSiteMetadata } from "../utils/hooks/useSiteMetadata"
import { Link } from "gatsby"

const SignUp = ({ setNewUser, classes }) => {
  const { state, dispatch } = useContext(UserContext)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [password1, setPassword1] = useState("")
  const [password2, setPassword2] = useState("")
  const [warning, setWarning] = useState("")

  const { sbuId } = useSiteMetadata()
  useDidMountEffect(() => {
    navigate("/my")
  }, [state.isLoggedIn])
  // function handleFindPerson() {
  //   FindPerson()
  //   )
  // }
  // const [handleSubmit, { loading, error, data }] = useMutation(
  //   SIGNUP_MUTATION,
  //   {
  //     variables: {
  //       email: email,
  //       password: password,
  //     },
  // refetchQueries: [{ query: LOGIN_QUERY }],
  // refetchQueries: [
  //   { query: FIND_PERSON_QUERY, variables: { emailAccount: email } },
  // ],
  // onCompleted() {
  //   return (
  //     <FindPerson email={email} firstName={firstName} lastName={lastName} />
  //   )
  // return (
  //   <FindPerson email={email} firstName={firstName} lastName={lastName} />
  // )
  // handleFindPerson()
  // },
  //   }
  // )

  const [handleSubmit, { loading, error }] = useMutation(SIGNUP_MUTATION, {
    variables: {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password1,
      sbuId: sbuId,
    },
    onCompleted: data => {
      dispatch({ type: "SET_LOGIN" })
      handleLogin()
    },
  })

  const [handleLogin] = useMutation(LOGIN_MUTATION, {
    variables: {
      email: email,
      password: password1,
    },
    onCompleted: data => {
      setNewUser(false)
    },
  })

  const checkCharacters = () => {
    var decimal =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/

    if (password1.match(decimal)) {
      setPassword(password1)
      return true
    } else {
      setWarning(
        "Please use a minimum of 8 characters including a number, a capitalized letter, a lowercase letter, and a symbol character like #$%^&*!."
      )
      return false
    }
  }

  const checkPassword = () => {
    if (password1 == "") {
      setWarning("Please enter a password")
      return false
    } else if (password2 == "") {
      setWarning("Please confirm password")
      return false
    } else if (password1 != password2) {
      setWarning("Passwords do not match. Please try again.")
      return false
    } else {
      setPassword(password1)
      return true
    }
  }

  const validate = () => {
    checkPassword() && checkCharacters() && handleSubmit()
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Paper square variant="outlined" className={classes.paper}>
          <Box className={classes.navBox}>
            <ButtonBase
              disableRipple
              onClick={e => setNewUser(false)}
              className={classes.inactiveTab}
            >
              <Typography className={classes.inactiveText}>Sign In</Typography>
            </ButtonBase>
            <ButtonBase
              disableRipple
              className={classes.activeTab}
              onClick={validate}
            >
              <Typography className={classes.activeText}>
                Create an Account
              </Typography>
            </ButtonBase>
          </Box>
          <div className={classes.formSection}>
            <form
              className={classes.form}
              noValidate
              onSubmit={event => {
                event.preventDefault()
                validate(event)
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="fname"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    value={firstName}
                    onChange={event => setFirstName(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="lname"
                    value={lastName}
                    onChange={event => setLastName(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                  />
                </Grid>
                <Typography variant="caption">
                  If you received a personalized registration link, please
                  follow that link instead of using this form to create your
                  account.
                </Typography>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    helperText="Please use a minimum of 8 characters including a number, a capitalized letter, a lowercase letter, and a symbol character like #$%^&*!."
                    id="password1"
                    autoComplete="current-password"
                    value={password1}
                    onChange={event => setPassword1(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Confirm Password"
                    type="password"
                    id="password2"
                    autoComplete="current-password"
                    value={password2}
                    onChange={event => setPassword2(event.target.value)}
                  />
                </Grid>
              </Grid>
              <ButtonBase
                className={classes.contrastButton}
                type="submit"
                disabled={
                  loading ||
                  !email.trim() ||
                  !password1.trim() ||
                  !password2.trim()
                }
              >
                <Typography
                  component="h1"
                  className={classes.boxTitle}
                  color="inherit"
                  gutterBottom
                >
                  {loading ? "Just a sec" : "Next"}
                </Typography>
              </ButtonBase>
              {/* <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={
                  loading ||
                  !email.trim() ||
                  !password1.trim() ||
                  !password2.trim()
                }
              >
                Sign Up
              </Button> */}
              <Grid container justify="flex-end">
                <Grid item xs={12}>
                  {warning.length > 3 && (
                    <Alert onClose={() => setWarning("")} severity="warning">
                      {warning}
                    </Alert>
                  )}
                </Grid>
              </Grid>
            </form>
          </div>
        </Paper>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    </div>
  )
}

const styles = theme => ({
  root: {
    marginTop: theme.spacing(7),
    display: "flex",
    flexDirection: "column",
  },
  paper: {
    backgroundColor: "#ffffff",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  navBox: {
    height: "60px",
    width: "100%",
    backgroundColor: "#F2F2F2",
    display: "flex",
  },
  activeTab: {
    height: "100%",
    width: "50%",
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontWeight: "bold",
    borderBottom: "5px solid #b71c1c",
    textAlign: "center",
  },
  activeText: {
    color: theme.palette.common.white,
    textTransform: "uppercase",
    fontWeight: "bold",
    textAlign: "center",
    padding: "15px 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  inactiveText: {
    color: theme.palette.common.black,
    textTransform: "uppercase",
    fontWeight: "bold",
    textAlign: "center",
    padding: "15px 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  inactiveTab: {
    height: "100%",
    width: "50%",
    fontWeight: "bold",
    backgroundColor: "#F2F2F2",
  },
  formSection: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  contrastButton: {
    width: "100%",
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    textTransform: "uppercase",
    fontWeight: "bold",
    padding: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.common.almostBlack,
    },
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(2),
  },
})

const SIGNUP_MUTATION = gql`
  mutation (
    $email: String!
    $password: String!
    $sbuId: Int
    $firstName: String
    $lastName: String
  ) {
    createUser(
      email: $email
      password: $password
      sbuId: $sbuId
      firstName: $firstName
      lastName: $lastName
    ) {
      user {
        email
      }
    }
  }
`

export const LOGIN_QUERY = gql`
  query {
    me {
      id
    }
  }
`
const FIND_PERSON_QUERY = gql`
  query ($emailAccount: String!) {
    emailAccount(emailAccount: $emailAccount) {
      emailAccount
      person {
        id
        firstName
        lastName
      }
    }
  }
`
const LOGIN_MUTATION = gql`
  mutation tokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      __typename
    }
  }
`

export default withStyles(styles)(SignUp)
