import React, { useState, useEffect } from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import MobileStepper from "@material-ui/core/MobileStepper"

import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Paper,
  Typography,
} from "@material-ui/core"

import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import SwipeableViews from "react-swipeable-views"
import { autoPlay } from "react-swipeable-views-utils"

import Draggable from "react-draggable"
import HeroCarousel from "react-hero-carousel"
const AutoPlaySwipeableViews = autoPlay(SwipeableViews)
// import AliceCarousel from "react-alice-carousel"
// import "react-alice-carousel/lib/alice-carousel.css"
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

const UserItemImages = ({ classes, itemImage }) => {
  const images = itemImage
  // const [images, setImages] = useState(itemImage)
  const [sliderImages, setSliderImages] = useState([])
  const [activeStep, setActiveStep] = useState(0)
  const [open, setOpen] = useState(false)
  // const [activeIndex, setActiveIndex] = useState(0)
  const maxSteps = images.length

  useEffect(() => {
    initializeImages()
  }, [])

  const initializeImages = () => {
    const imgArray = images.map((img, index) => {
      const sliderImg = img.carouselImageUrl
      return <img src={sliderImg} key={index} alt="image" />
    })
    setSliderImages(imgArray)
  }
  const handleNext = () => setActiveStep(prevActiveStep => prevActiveStep + 1)

  const handleBack = () => setActiveStep(prevActiveStep => prevActiveStep - 1)

  const handleStepChange = step => {
    setActiveStep(step)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  // const onInitialized = e => {
  //   // console.debug(`Start position(activeIndex) on init: ${e.item}. Event:`, e)
  //   e.initializeImages()
  //   setActiveStep(e.item)
  // }

  const onSlideChange = e => {
    console.debug(`Item's position before a change: ${e.item}. Event:`, e)
    setActiveStep(e.item)
  }

  const onSlideChanged = e => {
    console.debug(`Item's position after changes: ${e.item}. Event:`, e)
    setActiveStep(e.item)
  }
  const syncActiveStep = ({ item }) => setActiveStep(item)
  return (
    <div className={classes.root}>
      <Card elevation={0} className={classes.header} variant="outlined">
        <Box padding={1}>
          <Typography variant="overline" display="block" gutterBottom>
            {images && images[activeStep].title && images[activeStep].title}
          </Typography>
          {/* <AliceCarousel
            disableDotsControls
            disableButtonsControls
            // autoHeight
            // infinite
            // mouseTracking
            onSlideChanged={syncActiveStep}
            // onSlideChange={onSlideChange}
            onInitialized={onInitialized}
            activeIndex={activeStep}
            items={sliderImages}
          /> */}
          {/* <HeroCarousel>
            {images.map((step, index))}
          </HeroCarousel> */}
        </Box>
        {/* </Paper> */}

        {/* Dialog image pop-up */}
        <Dialog
          open={open}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          maxWidth={false}
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            {images && images[activeStep].title}
          </DialogTitle>
          <DialogContent>
            <img
              src={images && images[activeStep].imageUrl}
              alt={
                images && images[activeStep].title
                  ? images && images[activeStep].title
                  : "Item Image"
              }
            />
            <DialogContentText>
              {images && images[activeStep].note}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={handleClose}
              variant="outlined"
              color="secondary"
            >
              Close
            </Button>
            <Button
              href={images ? `${images[activeStep].imageUrl}` : undefined}
              download={images ? `${images[activeStep].image}` : undefined}
              variant="contained"
              color="primary"
            >
              Download
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Dialog image pop-up */}
        <CardContent>
          <AutoPlaySwipeableViews
            axis={"x-reverse"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
            interval={6000}
          >
            {images &&
              images.map((step, index) => (
                <div key={step.id}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <ButtonBase onClick={handleClickOpen}>
                      <img
                        className={classes.img}
                        src={step.carouselImageUrl}
                        alt={step.title}
                      />
                    </ButtonBase>
                  ) : null}
                </div>
              ))}
          </AutoPlaySwipeableViews>
        </CardContent>
        <Box padding={1}>
          <Typography variant="caption" display="block" gutterBottom>
            Image Note:{" "}
            {images && images[activeStep].note && images[activeStep].note}
          </Typography>
        </Box>

        <MobileStepper
          steps={maxSteps}
          position="static"
          variant="text"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              Next
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        />
      </Card>
    </div>
  )
}

const styles = theme => ({
  root: {
    // maxWidth: 300,

    minWidth: 225,
    flexGrow: 1,
  },
  paper: {
    // padding: theme.spacing(2),
    boxShadow: "none",
    backgroundColor: theme.palette.background.primary,
  },
  header: {
    // display: 'flex',
    alignItems: "center",
    borderRadius: "15px",
    // paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.primary,
  },
  img: {
    // height: 255,
    display: "block",
    maxWidth: 315,
    overflow: "hidden",
    width: "100%",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.common.black,
  },
  swipeBox: {
    marginLeft: "auto",
    marginRight: "auto",
  },
})

export default withStyles(styles)(UserItemImages)
